import VueRouter from 'vue-router'
import Dashboard from '@/pages/dashboard'
import Login from '@/pages/login'

const routes = [
    {
        path: '/',
        redirect: '/dashboard'
    },
    {
        path: '/dashboard',
        name: "Dashboard",
        component: Dashboard
    },
    {
        path: '/login',
        name: "Login",
        component: Login
    }
]

// configure router
const router = new VueRouter({
    mode: 'history',
    routes, // short for routes: routes
    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    },
    linkExactActiveClass: 'nav-item active'
    //mode: 'hash'
})

export default router