<template>
  <div class="col-12 row mx-auto m-0 p-0">
    <div class="col-xl-12 col-12 position-relative justify-content-center">
      <b-row>
        <b-col md="auto" class="ml-auto">
          <b-form-datepicker
            v-model="dateValue"
            class="mb-2"
          ></b-form-datepicker>
        </b-col>
        <b-col md="auto">
          <b-form-timepicker
            v-model="timeValue"
            show-seconds
            locale="en"
          ></b-form-timepicker>
        </b-col>
        <b-col md="auto" class="mr-auto">
          <b-button
            variant="btn btn-primary"
            title="Go to this date"
            class="mr-1"
            :class="onMobile ? 'mt-2' : ''"
            @click="applyManualDate()"
          >
            Apply
          </b-button>
        </b-col>
      </b-row>
      <hr />
      <div v-if="diagnostic">
        <b-row class="justify-content-md-center mb-5 p-0 m-0">
          <b-col cols="12" md="auto" class="p-0" style="width: 100%;">
            <div id="timeline">
              <b-button
                variant="btn btn-light d-btn"
                title="Oldest dignostics"
                class="mr-1"
                :class="onMobile ? ['ml-auto', 'mt-2'] : ''"
                @click="getOlderDiags()"
                id="timeline-b-l"
              >
                <b-icon icon="chevron-double-left" aria-hidden="true"></b-icon>
              </b-button>
              <b-button-group class="timeline-b-group">
                <b-button
                  id="timeline-times"
                  v-for="(item, index) in diagnosticOrderned"
                  :key="index"
                  :variant="color(item.data)"
                  class="timeline-buttons"
                  :pressed="isCursor(index)"
                  @click="changeDiag((index - (limit - 1)) * -1)"
                  :title="item.createdAt"
                ></b-button>
              </b-button-group>
              <b-button
                variant="btn btn-light d-btn"
                title="Newer dignostics"
                class="ml-1"
                :class="onMobile ? ['mr-auto', 'mt-2'] : ''"
                @click="handleButton()"
                id="timeline-b-r"
              >
                <span v-if="canGoRight">
                  <b-icon
                    icon="chevron-double-right"
                    aria-hidden="true"
                  ></b-icon>
                </span>
                <span v-else>
                  <b-icon
                    icon="arrow-counterclockwise"
                    aria-hidden="true"
                  ></b-icon>
                </span>
              </b-button>
              <b-button
                v-if="seeingOlderDiagnostics"
                variant="btn btn-light d-btn"
                title="Newest dignostics"
                class="ml-1"
                :class="onMobile ? ['mr-auto', 'mt-2'] : ''"
                @click="lastestDiagnostics()"
                id="timeline-b-rr"
              >
                <b-icon icon="chevron-bar-right" aria-hidden="true"></b-icon>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div v-for="(item, index) in actualDiagnostic" :key="index">
              <diagnostic-accordion
                :name="index"
                :content="item"
              ></diagnostic-accordion>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import DiagnosticAccordion from "./DiagnosticAccordion.vue";
export default {
  components: {
    DiagnosticAccordion,
  },
  name: "Diagnostics",

  props: {
    robotId: String,
  },
  data() {
    return {
      timeValue: "",
      dateValue: "",
      dignosticsLoading: false,

      actualDiag: Object,

      refDiags: [],
      refDiagsToRight: [],
      canGoRight: false,

      cursor: 0,
      needToSlice: false,
      seeingOlderDiagnostics: false,
      goingLeft: false,
      olderDateApplied: null,
      limit: 50,
      onMobile: false,
    };
  },

  async created() {
    if (screen.width <= 760) this.isMobile();

    let args = {
      robotId: this.robotId,
      limit: this.limit,
    };

    await this.retriveDiagnostic(args);
    if (this.diagnostic) {
      this.actualDiag = await this.diagnostic[0].data;
      let date = new Date(this.diagnostic[0].createdAt);
      this.dateValue = `${date.getFullYear()}-${date.getMonth() +
        1}-${date.getDate()}`;
      this.timeValue = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      this.cursor = 0;
    }
  },

  computed: {
    ...mapState({
      diagnosticLoading: (state) => state["telemetry"].diagnosticLoading,
      diagnostic: (state) => state["telemetry"].diagnostic,
    }),

    selectedDatetime() {
      let date = new Date();

      date.setFullYear(this.dateValue.split("-")[0]);
      date.setMonth(this.dateValue.split("-")[1] - 1);
      date.setDate(this.dateValue.split("-")[2]);

      date.setHours(this.timeValue.split(":")[0]);
      date.setMinutes(this.timeValue.split(":")[1]);
      date.setSeconds(this.timeValue.split(":")[2]);
      date.setMilliseconds(0);

      return date.toISOString();
    },

    actualDiagnostic() {
      return this.actualDiag;
    },

    goRight() {
      if (this.canGoRight) return false;
      else return true;
    },

    diagnosticOrderned() {
      if (this.seeingOlderDiagnostics && !this.goingLeft)
        return this.diagnostic.slice();
      else return this.diagnostic.slice().reverse();
    },
  },
  methods: {
    ...mapActions("missions", {
      getMissionMetrics: "getMetrics",
    }),
    ...mapActions("telemetry", {
      retriveDiagnosticByGteDatetime: "retriveDiagnosticByGteDatetime",
      retriveDiagnostic: "retriveDiagnostic",
    }),

    isCursor(index) {
      if (this.seeingOlderDiagnostics && !this.goingLeft) {
        if (index == this.cursor) return true;
        else return false;
      } else {
        if ((index - (this.limit - 1)) * -1 == this.cursor) return true;
        else return false;
      }
    },

    handleButton() {
      if (this.canGoRight) this.getNewerDiags();
      else this.refreshData();
    },

    async lastestDiagnostics() {
      this.seeingOlderDiagnostics = false;
      this.canGoRight = false;
      let args = {
        robotId: this.robotId,
        limit: this.limit,
      };

      await this.retriveDiagnostic(args);
      if (this.diagnostic) {
        this.actualDiag = await this.diagnostic[0].data;
        let date = new Date(this.diagnostic[0].createdAt);
        this.dateValue = `${date.getFullYear()}-${date.getMonth() +
          1}-${date.getDate()}`;
        this.timeValue = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
        this.cursor = 0;
      }
    },

    async applyManualDate() {
      this.olderDateApplied = this.selectedDatetime;
      this.seeingOlderDiagnostics = true;

      this.canGoRight = true;
      let args = {
        robotId: this.robotId,
        gte: this.selectedDatetime,
        limit: this.limit,
      };

      await this.retriveDiagnosticByGteDatetime(args);
      if (this.diagnostic) {
        this.actualDiag = await this.diagnostic[0].data;
        let date = new Date(this.diagnostic[0].createdAt);
        this.dateValue = `${date.getFullYear()}-${date.getMonth() +
          1}-${date.getDate()}`;
        this.timeValue = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
        this.cursor = 0;
      }
    },

    async refreshData() {
      let args = {
        robotId: this.robotId,
        limit: this.limit,
      };
      await this.retriveDiagnostic(args);
      if (this.diagnostic) {
        this.actualDiag = await this.diagnostic[0].data;
        let date = new Date(this.diagnostic[0].createdAt);
        this.dateValue = `${date.getFullYear()}-${date.getMonth() +
          1}-${date.getDate()}`;
        this.timeValue = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
        this.cursor = 0;
      }
    },

    async getOlderDiags() {
      this.goingLeft = true;
      this.needToSlice = true;
      this.canGoRight = true;
      this.refDiags.push(this.diagnostic[this.limit - 1].createdAt);
      let args = {
        robotId: this.robotId,
        limit: this.limit,
        lastKey: this.diagnostic[this.limit - 1].createdAt,
      };
      await this.retriveDiagnostic(args);
      if (this.diagnostic) {
        this.actualDiag = await this.diagnostic[0].data;
        let date = new Date(this.diagnostic[0].createdAt);
        this.dateValue = `${date.getFullYear()}-${date.getMonth() +
          1}-${date.getDate()}`;
        this.timeValue = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
        this.cursor = 0;
      }
    },

    async getNewerDiags() {
      this.goingLeft = false;
      if (!this.seeingOlderDiagnostics) {
        if (this.needToSlice) {
          this.needToSlice = false;
          this.refDiags.pop();
        }
        let key = this.refDiags.pop();
        if (key != undefined) {
          let args = {
            robotId: this.robotId,
            limit: this.limit,
            lastKey: key,
          };
          await this.retriveDiagnostic(args);
          if (this.diagnostic) {
            this.actualDiag = await this.diagnostic[this.limit - 1].data;
            let date = new Date(this.diagnostic[this.limit - 1].createdAt);
            this.dateValue = `${date.getFullYear()}-${date.getMonth() +
              1}-${date.getDate()}`;
            this.timeValue = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
            this.cursor = this.limit - 1;
          }
        } else {
          this.canGoRight = false;
          let args = {
            robotId: this.robotId,
            limit: this.limit,
          };
          await this.retriveDiagnostic(args);
          if (this.diagnostic) {
            this.actualDiag = await this.diagnostic[this.limit - 1].data;
            let date = new Date(this.diagnostic[this.limit - 1].createdAt);
            this.dateValue = `${date.getFullYear()}-${date.getMonth() +
              1}-${date.getDate()}`;
            this.timeValue = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
            this.cursor = this.limit - 1;
          }
        }
      } else {
        this.canGoRight = true;
        if (this.needToSlice) {
          this.needToSlice = false;
          this.refDiags.pop();
        }
        let key = this.refDiags.pop();
        if (key != undefined) {
          let args = {
            robotId: this.robotId,
            limit: this.limit,
            lastKey: key,
          };
          await this.retriveDiagnostic(args);
          if (this.diagnostic) {
            this.actualDiag = await this.diagnostic[0].data;
            let date = new Date(this.diagnostic[0].createdAt);
            this.dateValue = `${date.getFullYear()}-${date.getMonth() +
              1}-${date.getDate()}`;
            this.timeValue = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
            this.cursor = 0;
          }
        } else {
          let args = {
            robotId: this.robotId,
            limit: this.limit,
            gte: this.diagnostic[this.limit - 1].createdAt,
          };
          await this.retriveDiagnosticByGteDatetime(args);
          if (this.diagnostic) {
            this.actualDiag = await this.diagnostic[this.limit - 1].data;
            let date = new Date(this.diagnostic[this.limit - 1].createdAt);
            this.dateValue = `${date.getFullYear()}-${date.getMonth() +
              1}-${date.getDate()}`;
            this.timeValue = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
            this.cursor = this.limit - 1;
          }
        }
      }
    },

    color(list) {
      let max = this.removeItemAll(
        Object.keys(list).map((key) => list[key].level),
        3
      ).sort((a, b) => b - a)[0];
      let className = "secondary";
      switch (max) {
        case 0:
          className = "success";
          break;
        case 1:
          className = "warning";
          break;
        case 2:
          className = "danger";
          break;
        case 3:
          className = "info";
          break;
        default:
          "secondary";
          break;
      }
      return className;
    },

    changeDiag(index) {
      if (this.seeingOlderDiagnostics && !this.goingLeft)
        index = (index - (this.limit - 1)) * -1;
      this.cursor = index;
      this.actualDiag = this.diagnostic[index].data;
      let date = new Date(this.diagnostic[index].createdAt);
      this.dateValue = `${date.getFullYear()}-${date.getMonth() +
        1}-${date.getDate()}`;
      this.timeValue = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    },

    removeItemAll(arr, value) {
      var i = 0;
      while (i < arr.length) {
        if (arr[i] === value) {
          arr.splice(i, 1);
        } else {
          ++i;
        }
      }
      return arr;
    },

    isMobile() {
      this.limit = 20;
      this.onMobile = true;
    },
  },
};
</script>
<style lang="css" scoped>
.d-btn {
  border: 1px solid #ced4da;
  background-color: #fff;
}

.timeline-buttons {
  padding: 8px 4px 8px 4px;
}

.timeline-b-group {
  width: auto;
  overflow-x: auto;
}

#timeline {
  display: flex;
  justify-content: center;
}

#timeline-times {
  margin-top: 10px;
  height: 0px;
}

@media (max-width: 760px) {
  #timeline {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  }
  #timeline-b-l {
    order: 2;
  }
  #timeline-b-r {
    order: 3;
  }
  #timeline-b-rr {
    order: 4;
  }
  #timeline-times {
    order: 1;
    width: 100%;
  }
  .timeline-buttons {
    padding: 12px 12px 12px 12px;
  }
  .timeline-b-group {
    width: 100%;
  }
}
</style>
