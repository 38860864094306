import axios from "axios";
import TokenService from "./storage.service";

function replaceQueryParam(param, newval, search) {
  var regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
  var query = search.replace(regex, "$1").replace(/&$/, "");

  return (
    (query.length > 2 ? query + "&" : "?") +
    (newval ? param + "=" + newval : "")
  );
}

const ApiService = {
  _401interceptor: null,

  mount401Interceptor(redirect_url_full) {
    this._401interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.request.status == 401) {
          window.location.href = redirect_url_full;
        }

        // If error was not 401 just reject as is
        throw error;
      }
    );
  },

  unmount401Interceptor() {
    // Eject the interceptor
    axios.interceptors.response.eject(this._401interceptor);
  },

  init(baseURL, loginDefaultUrl) {
    axios.defaults.baseURL = baseURL;
    this.loginDefaultUrl = loginDefaultUrl;
  },

  setHeader() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${TokenService.getToken()}`;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource) {
    return axios.get(resource);
  },

  post(resource, data) {
    return axios.post(resource, data);
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  patch(resource, data) {
    return axios.patch(resource, data);
  },

  delete(resource, config) {
    return axios.delete(resource, config);
  },

  async download(url) {
    let result = await axios({
      method: "get",
      url,
      headers: {
        "Access-Control-Request-Method": "GET",
        "Access-Control-Request-Headers": "X-Requested-With",
        common: {},
      },
    });

    return axios({
      method: "get",
      url: result.data,
      transformRequest: (data, headers) => {
        delete headers.common["Authorization"];
      },
      responseType: "arraybuffer",
      headers: {
        "Access-Control-Request-Method": "GET",
        "Access-Control-Request-Headers": "X-Requested-With",
      },
    });
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data) {
    return axios(data);
  },
  redirectToLogin(clientId) {
    if (clientId) {
      window.location.href = replaceQueryParam(
        "client_id",
        clientId,
        this.loginDefaultUrl
      );
    } else {
      window.location.href = this.loginDefaultUrl;
    }
  },
};

export default ApiService;
export { ApiService };
