<template>
  <div class="col-12 row p-0 m-0">
    <div class="col-12 text-center mb-3 mt-2">
      <date-range-picker
        ref="picker"
        opens="center"
        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
        v-model="dateRangeDisplacement"
        :timePicker="true"
        :time-picker24-hour="true"
        :auto-apply="false"
        :ranges="ranges.default()"
        :show-week-numbers="true"
        :linked-calendars="false"
      >
        <template #input="picker" style="min-width: 350px;">
          <i class="glyphicon glyphicon-calendar fa fa-calendar"></i>&nbsp;
          <span v-if="picker.rangeText !== ' - '">{{ picker.rangeText }}</span>
          <span v-else>All time</span>
          <b class="caret"></b>
        </template>
      </date-range-picker>
    </div>
    <div class="col-xl-6 col-12">
      <div class="table-header">Displacement by status</div>
      <b-table
        hover
        :items="missionDisplacementsByStatus"
        :tbody-tr-class="rowClass"
        :selectable="true"
        select-mode="single"
        @row-selected="selectedDisplacementStatus"
      ></b-table>
    </div>
    <div class="col-xl-6 col-12">
      <highcharts :options="missionDisplacementOptions"></highcharts>
    </div>
    <div class="col-12"><hr /></div>
    <div class="col-12 text-center mb-3 mt-2">
      <date-range-picker
        ref="picker"
        opens="center"
        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
        v-model="dateRangeResults"
        :timePicker="true"
        :time-picker24-hour="true"
        :auto-apply="false"
        :ranges="ranges.default()"
        :show-week-numbers="true"
        :linked-calendars="false"
      >
        <template #input="picker" style="min-width: 350px;">
          <i class="glyphicon glyphicon-calendar fa fa-calendar"></i>&nbsp;
          <span v-if="picker.rangeText !== ' - '">{{ picker.rangeText }}</span>
          <span v-else>All time</span>
          <b class="caret"></b>
        </template>
      </date-range-picker>
    </div>
    <div class="col-xl-6 col-12 position-relative">
      <div class="position-relative">
        <highcharts :options="missionChartOptions"></highcharts>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import DateRangePicker from "vue2-daterange-picker";

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "Metrics",
  components: {
    DateRangePicker,
  },
  props: {
    robotId: String,
  },
  data() {
    return {
      ranges: {
        default() {
          let lastHour = new Date();
          lastHour.setHours(lastHour.getHours() - 1);
          let lastHourEnd = new Date();

          let last12Hours = new Date();
          last12Hours.setHours(last12Hours.getHours() - 12);

          let today = new Date();
          today.setHours(0, 0, 0, 0);
          let todayEnd = new Date();
          todayEnd.setHours(11, 59, 59, 999);
          let yesterdayStart = new Date();
          yesterdayStart.setDate(today.getDate() - 1);
          yesterdayStart.setHours(0, 0, 0, 0);
          let yesterdayEnd = new Date();
          yesterdayEnd.setDate(today.getDate() - 1);
          yesterdayEnd.setHours(11, 59, 59, 999);

          let thisMonthStart = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          );
          let thisMonthEnd = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0,
            11,
            59,
            59,
            999
          );
          return {
            "Last Hour": [lastHour, lastHourEnd],
            "Last 12 Hours": [last12Hours, lastHourEnd],
            Today: [today, todayEnd],
            Yesterday: [yesterdayStart, yesterdayEnd],
            "This month": [thisMonthStart, thisMonthEnd],
            "Last month": [
              new Date(today.getFullYear(), today.getMonth() - 1, 1),
              new Date(
                today.getFullYear(),
                today.getMonth(),
                0,
                11,
                59,
                59,
                999
              ),
            ],
            "This year": [
              new Date(today.getFullYear(), 0, 1),
              new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999),
            ],
            "All Time": [null, null],
          };
        },
      },
      dateRangeDisplacement: {
        startDate: null,
        endDate: null,
      },
      dateRangeResults: {
        startDate: null,
        endDate: null,
      },
      missionChartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
        },
        title: {
          text: "Results of Missions",
        },
        tooltip: {
          pointFormat:
            "{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
          },
        },
        series: [
          {
            name: "Executions",
            colorByPoint: true,
            data: [],
          },
        ],
      },

      missionDisplacementOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          type: "line",
          scrollablePlotArea: {
            minWidth: 500,
          },
        },

        data: {
          beforeParse: function(csv) {
            return csv.replace(/\n\n/g, "\n");
          },
        },

        title: {
          text: "Displacement by interval",
        },

        xAxis: {
          type: "datetime",
          labels: {
            format: "{value: %Y-%m-%d %H:%M}",
          },
        },

        yAxis: [
          {
            // left y axis
            title: {
              text: null,
            },
            labels: {
              align: "left",
              x: 3,
              y: 16,
              format: "{value:.,0f}m",
            },
            showFirstLabel: false,
          },
        ],

        legend: {
          align: "left",
          verticalAlign: "top",
          borderWidth: 0,
        },

        tooltip: {
          shared: true,
          crosshairs: true,
        },

        plotOptions: {
          series: {
            cursor: "pointer",
            className: "popup-on-click",
            marker: {
              lineWidth: 1,
            },
          },
        },

        series: [
          {
            name: "total",
            lineWidth: 4,

            color: "#7abaff",
            marker: {
              radius: 4,
            },
            data: [
              [new Date("2021-06-08T21:21:51.000Z").getTime(), 0],
              [new Date("2021-06-09T21:21:51.000Z").getTime(), 0],
            ],
          },
        ],
      },

      displacementArgs: {
        robotId: this.robotId,
        result_status: null,
        date_begin: null,
        date_end: null,
      },

      displacementStatusArgs: {
        robotId: this.robotId,
        date_begin: null,
        date_end: null,
      },
    };
  },

  created() {
    this.missionFillData();
    this.getMissionDisplacements(this.displacementArgs);
    this.getMissionDisplacementsByStatus({ robotId: this.robotId });

    this.getMissionMetrics({ robotId: this.robotId });
  },

  computed: {
    ...mapState({
      missionMetrics: (state) => state["missions"].metrics,
      missionMetricsLoading: (state) => state["missions"].metricsLoading,
      missionDisplacements: (state) => state["missions"].displacements,
      missionDisplacementsStatus: (state) =>
        state["missions"].displacementsByStatus,
    }),
    missionDisplacementsByStatus() {
      if (!this.missionDisplacementsStatus) return [];

      let data = [
        {
          status: "total",
          displacement: this.missionDisplacementsStatus.total,
        },
      ];

      if (this.missionDisplacementsStatus.data) {
        data = [
          ...data,
          ...Object.keys(this.missionDisplacementsStatus.data)
            .map((key) => {
              return {
                status: key,
                displacement: this.missionDisplacementsStatus.data[key],
              };
            })
            .filter(
              (item) =>
                item.status === "preempted" ||
                item.status == "succeeded" ||
                item.status == "aborted"
            ),
          {
            status: "others",
            displacement: Object.keys(this.missionDisplacementsStatus.data)
              .filter(
                (key) =>
                  key != "preempted" && key != "succeeded" && key != "aborted"
              )
              .map((key) => this.missionDisplacementsStatus.data[key])
              .reduce((p, c) => p + c, 0),
          },
        ];
      }

      return data.map((item) => {
        return {
          status: item.status,
          displacement: item.displacement.toFixed(2) + " m",
        };
      });
    },
  },
  methods: {
    ...mapActions("missions", {
      getMissionMetrics: "getMetrics",
      getMissionDisplacements: "getDisplacements",
      getMissionDisplacementsByStatus: "getDisplacementsByStatus",
    }),

    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() < new Date();
      }
      return classes;
    },

    selectedDisplacementStatus(item) {
      if (item.length > 0) {
        this.displacementArgs.result_status = {
          total: null,
          succeeded: 3,
          preempted: 2,
          aborted: 4,
          others: "0,1,5,6,7,8",
        }[item[0].status];
        this.missionDisplacementOptions.series[0].name = item[0].status;
        this.missionDisplacementOptions.series[0].data = [];
        let colors = {
          total: "#7abaff",
          preempted: "#ffdf7e",
          succeeded: "#8fd19e",
          aborted: "#ed969e",
          others: "#1b1e21",
        };
        this.missionDisplacementOptions.series[0].color =
          colors[item[0].status];
      }
    },

    missionFillData() {
      let data = [];

      let colors = {
        inactive: "#929395",
        active: "#b8daff",
        preempted: "#fff3cd",
        succeeded: "rgb(144,237,125)",
        aborted: "rgb(241,92,128)",
        rejected: "#721c24",
        preempting: "#856404",
        recalling: "#ffeeba",
        lost: "#1b1e21",
      };

      Object.keys(this.missionMetrics).forEach((key) => {
        let value = this.missionMetrics[key];
        if (key == "succeeded")
          data.push({
            name: key.charAt(0).toUpperCase() + key.slice(1),
            y: value,
            sliced: true,
            selected: true,
            color: colors[key],
          });
        else
          data.push({
            name: key.charAt(0).toUpperCase() + key.slice(1),
            y: value,
            color: colors[key],
          });
      });
      this.missionChartOptions.series[0].data = data;
    },

    displacementsFillData() {
      let data = [];
      if (!this.missionDisplacements) {
        this.missionDisplacementOptions.series[0].data = [];
        return;
      }
      for (
        let i = this.missionDisplacements.date_begin;
        i < this.missionDisplacements.date_end;
        i += this.missionDisplacements.period_span
      ) {
        data.push([i * 1000, 0]);
      }

      this.missionDisplacements.data.forEach((item) => {
        data.push([item.period * 1000, item.total]);
      });

      this.missionDisplacementOptions.xAxis.min =
        (this.missionDisplacements.date_begin -
          this.missionDisplacements.period_span) *
        1000;
      this.missionDisplacementOptions.xAxis.max =
        this.missionDisplacements.date_end * 1000;
      this.missionDisplacementOptions.series[0].data = data.sort(
        (a, b) => a[0] - b[0]
      );
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "succeeded") return "table-success pointer";
      if (item.status === "aborted") return "table-danger pointer";
      if (item.status === "preempted") return "table-warning pointer";
      if (item.status === "total") return "table-primary pointer";
      if (item.status === "others") return "table-light pointer";
    },
  },

  watch: {
    missionMetrics: function() {
      this.missionFillData();
    },

    missionDisplacements: function() {
      this.displacementsFillData();
    },

    displacementArgs: {
      handler: function(newObj) {
        this.getMissionDisplacements(newObj);
      },
      deep: true,
    },
    displacementStatusArgs: {
      handler: function(newObj) {
        this.getMissionDisplacementsByStatus(newObj);
      },
      deep: true,
    },
    dateRangeDisplacement: {
      handler: function(val) {
        this.displacementArgs.date_begin = (
          new Date(val.startDate).getTime() / 1000
        ).toFixed(0);
        this.displacementArgs.date_end = (
          new Date(val.endDate).getTime() / 1000
        ).toFixed(0);

        this.displacementStatusArgs.date_end = this.displacementArgs.date_end;
        this.displacementStatusArgs.date_begin = this.displacementArgs.date_begin;
      },
      deep: true,
    },
    dateRangeResults: {
      handler: function(newObj) {
        this.getMissionMetrics({
          robotId: this.robotId,
          date_begin: (new Date(newObj.startDate).getTime() / 1000).toFixed(0),
          date_end: (new Date(newObj.endDate).getTime() / 1000).toFixed(0),
        });
      },
      deep: true,
    },
  },
};
</script>
<style>
.table-header {
  color: #333333;
  font-size: 18px;
  fill: #333333;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 0.3rem;
}
.pointer {
  cursor: pointer !important;
}

.slot {
  background-color: #aaa;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (min-width: 720px) {
  .daterangepicker {
    min-width: 720px !important;
  }
}
.drp-calendar.col {
  min-width: auto !important;
}
.text-black {
  color: #000;
}
.form-control.reportrange-text {
  height: auto !important;
}
</style>
