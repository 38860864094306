class Mission {
    constructor() {
        this.uuid = 0,
        this.robot = 0,
        this.name = '',
        this.description = '',
        this.steps = [],
        this.datetime_created = '',
        this.datetime_updated = '',
        this.active = false
    }
}

export default Mission
export {Mission}