const state = {
    connected: false,
    connecting: false,
    error: null,
    lastMessageTimestamp: 0
}

const getters = {}

const actions = {
    connect({ commit }, robot) {
        console.log('called connect');
        commit('SOCKET_CONNECT', {robot})
    }
}

const mutations = {
    SOCKET_CONNECT(state) {
        console.log('called SOCKET_CONNECT');
        state.connecting = true
    },

    SOCKET_CONNECTED(state) {
        state.connecting = false
        state.connected = true
    },
    SOCKET_DISCONNECTED(state) {
        state.connecting = false
        state.connected = false
        state.lastMessageTimestamp = 0
    },
    SOCKET_ERROR(state, error) {
        state.error = error
    },
    SOCKET_MESSAGE(state) {
        state.lastMessageTimestamp = (new Date()).getTime()
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}