<template>
  <div class="accordion-content">
    <b-row
      align-h="between"
      v-b-toggle="'accordion-' + name + random"
      class="py-1 accordion"
    >
      <span v-if="Object.keys(content).length > 2">
        <b-icon
          class="when-closed"
          icon="chevron-down"
          aria-hidden="true"
          shift-v="-2"
        ></b-icon>
        <b-icon
          class="when-opened"
          icon="chevron-up"
          aria-hidden="true"
          shift-v="-2"
        ></b-icon>
      </span>
      <span v-else>
        <b-icon
          class="when-closed invisible"
          icon="chevron-down"
          aria-hidden="true"
          shift-v="-2"
        ></b-icon>
        <b-icon
          class="when-opened invisible"
          icon="chevron-up"
          aria-hidden="true"
          shift-v="-2"
        ></b-icon>
      </span>
      <b-col cols="auto" class="name mr-auto">{{ replaceAll(name) }}</b-col>
      <b-col cols="auto">
        <h6 class="status">
          <b-badge :class="statusColor" class="py-1 px-2">{{
            statusMessage
          }}</b-badge>
        </h6>
      </b-col>
    </b-row>
    <b-collapse
      :id="'accordion-' + name + random"
      v-model="visible"
      class="accordion-inside"
    >
      <b-col>
        <div v-for="(item, index) in content" :key="index">
          <div v-if="index != 'message' && index != 'level'">
            <DiagnosticAccordion
              :name="index"
              :content="item"
            ></DiagnosticAccordion>
          </div>
        </div>
      </b-col>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "DiagnosticAccordion",
  data() {
    return {
      random: Math.random(),
      visible: false,
    };
  },
  props: {
    name: String,
    content: Object,
  },
  created() {
    if (this.content.level != 0) this.visible = true;
  },
  computed: {
    statusColor() {
      let className = "bg-secondary";
      switch (this.content.level) {
        case 0:
          className = "bg-success";
          break;
        case 1:
          className = "bg-warning";
          break;
        case 2:
          className = "bg-danger";
          break;
        case 3:
          className = "bg-info";
          break;
        default:
          "bg-secondary";
          break;
      }
      return className;
    },
    statusMessage() {
      let message = "OK";
      switch (this.content.level) {
        case 0:
          message = "OK";
          break;
        case 1:
          message = "Warning";
          break;
        case 2:
          message = "Error";
          break;
        case 3:
          message = "Stale";
          break;
        default:
          "OK";
          break;
      }
      return message;
    },
  },
  methods: {
    replaceAll(val) {
      return val.replace(new RegExp("_", "g"), " ");
    },
  },
};
</script>

<style>
.name {
  text-transform: capitalize;
}
.status {
  display: inline;
}
.accordion {
  border-bottom: 1px solid #000;
  align-items: center;
}
.collapsed > span > .when-opened,
.not-collapsed > span > .when-closed {
  display: none;
}
.invisible {
  visibility: hidden;
}
.accordion-inside {
  margin-right: 0px;
}
.accordion-content .col {
  padding-right: 0px !important;
}
</style>
