import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import Amplify, * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import aws_exports from "./aws-exports";
import VueLodash from "vue-lodash";
import lodash from "lodash";
// router setup
import router from "./router";
import TokenService from "./services/storage.service";
import ApiService from "./services/_api.service";
import store from "./store";
import "./assets/styles/custom.scss";

import HighchartsVue from "highcharts-vue";
import JSONView from "vue-json-component";
import Nipple from "vue-nipple";
var VueTouch = require("vue-touch");
Vue.use(VueTouch, { name: "v-touch" });

Vue.component("vue-nipple", Nipple);
Vue.use(JSONView);

Vue.use(HighchartsVue);
Vue.use(VueRouter);
Vue.use(VueLodash, { name: "custom", lodash: lodash });

Amplify.configure(aws_exports);
Vue.use(AmplifyPlugin, AmplifyModules);
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(require("vue-easeljs"));
Vue.config.productionTip = false;

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

const getApiConfig = async () => {
  const apiConfig = await fetch("/data/api-config.json");
  return await apiConfig.json();
};
getApiConfig().then(function(json) {
  Vue.mixin({
    data() {
      return {
        // Distribute runtime configs into every Vue component
        API_ENDPOINT: json.API,
        LOGIN_PAGE_BASE: json.LOGIN_PAGE_BASE,
        LOGIN_REDIRECT_URL: json.LOGIN_REDIRECT_URL,
        DEFAULT_CLIENT_ID: json.DEFAULT_CLIENT_ID,
      };
    },
  });

  let client_id = json.DEFAULT_CLIENT_ID;

  // If token exists set header
  if (TokenService.getToken()) {
    ApiService.setHeader();
    client_id = parseJwt(TokenService.getToken()).client_id;
  }

  if (!client_id) client_id = json.DEFAULT_CLIENT_ID;

  let host = window.location.protocol + "//" + window.location.host + "/login";
  let redirect_url_full = `${json.LOGIN_PAGE_BASE}?client_id=${client_id}&response_type=token&redirect_uri=${host}`;
  ApiService.mount401Interceptor("/login");
  ApiService.init(json.API, redirect_url_full);

  new Vue({
    el: "#app",
    router,
    store,
    render: (h) => h(App),
  });
});
