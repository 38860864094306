import TokenService from "@/services/storage.service";

const mapServices = {
  missions: {
    module: "missions",
    actions: {
      ExecutionMission: {
        mutation: "lastUpdatesSuccess",
        parser: (data) => {
          return {
            next: null,
            page: 1,
            previous: null,
            executions: [data],
          };
        },
      },
    },
  },
  telemetry: {
    module: "telemetry",
    actions: {
      RetrieveRobotPose: {
        mutation: "robotPoseSuccess",
      },
    },
  },
};

export default function websocket() {
  return (store) => {
    var socket = null;

    store.subscribe((mutation) => {
      if (mutation.type === "SOCKET_CONNECT") {
        const robot = mutation.payload.robot;
        const token = TokenService.getToken();
        if (!robot || !token) return;
        const url = `wss://sockets.sara.synkar.com/v1?token=${token}&robotId=${robot}`;

        if (socket !== null) {
          store.commit("SOCKET_DISCONNECTED");
          socket.close();
        }

        socket = new WebSocket(url);
        socket.onmessage = (event) => {
          console.log(event);

          store.commit("SOCKET_MESSAGE");

          if (event.data) {
            let data = event.data;
            if (typeof data === "string") data = JSON.parse(data);

            if (data && data.service) {
              const service = mapServices[data.service];
              if (service && service.actions) {
                const action = service.actions[data.action];
                if (action) {
                  const mutation = action.mutation;
                  const parser = service.actions[data.action].parser;
                  if (mutation) {
                    store.commit(
                      `${service.module}/${mutation}`,
                      parser ? parser(data.data) : data.data
                    );
                  }
                }
              }
            }
          }
        };
        socket.onopen = () => {
          store.commit("SOCKET_CONNECTED");
        };
        socket.onerror = (error) => {
          store.commit("SOCKET_ERROR", error);
        };
        socket.onclose = () => {
          store.commit("SOCKET_DISCONNECTED");
        };
      }
    });
  };
}
