import MissionService from "@/services/missions.service";
import _ from "lodash";

const state = {
  missions: [],
  missionsCopy: [],
  missionsLoading: false,
  execution: null,
  executionStarted: null,

  lastUpdatesByMission: [],
  lastUpdatesLoading: false,

  lastEvents: [],

  metrics: {
    inactive: 1,
    active: 1,
    preempted: 1,
    succeeded: 1,
    aborted: 1,
    rejected: 1,
    preempting: 1,
    recalling: 1,
    lost: 1,
  },
  displacements: {
    data: [],
  },
  displacementsByStatus: {
    total: 0,
    data: [],
  },
  displacementsByStatusLoading: false,
  displacementsLoading: false,
  metricsLoading: false,
};

const getters = {};

const actions = {
  makeMissionCopy: ({ commit }, copy) => {
    commit("makeMissionCopySuccess", copy);
  },

  listByRobotId: async ({ commit }, robotId) => {
    if (!robotId) return;
    try {
      let results = MissionService.listByRobotId(robotId);
      commit("listByRobotIdLoading", true);
      results = await results;
      commit("listByRobotIdSuccess", results.data);
      commit("listByRobotIdLoading", false);
    } catch (e) {
      commit("listByRobotIdLoading", false);
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  create: async ({ commit }, model) => {
    try {
      let index = model.index;
      delete model.index;
      let results = await MissionService.create(model);
      commit("createSuccess", { data: results.data, index });
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  update: async ({ commit }, model) => {
    try {
      let results = await MissionService.update(model.uuid, model);
      commit("updateSuccess", results.data);
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  delete: async ({ commit }, id) => {
    try {
      await MissionService.delete(id);
      commit("deleteSuccess", id);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  execute: async ({ commit }, missionId) => {
    try {
      let result = await MissionService.execute(missionId);
      commit("executionSuccess", result.data);
      commit("executionStartedSuccess", result.data);
      commit("lastUpdatesSuccess", {
        executions: [result.data],
        page: 1,
        next: null,
        previous: null,
      });
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  cancel: async ({ commit }) => {
    try {
      if (!state.execution) return;
      await MissionService.cancel(state.execution.mission);
      commit("cancelSuccess");
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  getExecutionById: async ({ commit }, id) => {
    try {
      let result = await MissionService.getExecutionById(id);
      commit("executionSuccess", result.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },
  clearExecution: async ({ commit }) => {
    commit("executionSuccess", null);
  },

  clearExecutionStarted: async ({ commit }) => {
    commit("executionStartedSuccess", null);
  },

  clearLastEvents: async ({ commit }, newLastEvents = []) => {
    commit("clearLastEventsSuccess", newLastEvents);
  },

  getLastExecutionsUpdates: async ({ commit }, args) => {
    try {
      if (!args && !args.robot_id) return;
      let result = MissionService.lastUpdatesGT(args.robot_id, args.datetime);
      commit("lastUpdatesLoadingSuccess");
      result = await result;
      commit("lastUpdatesSuccess", result.data);
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  moreEvents: async ({ commit }, url) => {
    try {
      if (!url) return;
      let result = MissionService.getByUrl(url);
      commit("lastUpdatesLoadingSuccess");
      result = await result;
      commit("moreEventsSuccess", { data: result.data, id: url });
      return null;
    } catch (e) {
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  getMetrics: async ({ commit }, args) => {
    try {
      if (!args.robotId) return;

      let result = MissionService.metrics(
        args.robotId,
        args.date_begin,
        args.date_end
      );
      state.metricsLoading = true;
      result = await result;
      state.metricsLoading = false;
      commit("metricsSuccess", result.data);
      return null;
    } catch (e) {
      state.metricsLoading = false;
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },
  getDisplacements: async ({ commit }, args) => {
    try {
      if (!args.robotId) return;

      let result = MissionService.displacements(
        args.robotId,
        args.date_begin,
        args.date_end,
        args.result_status
      );
      state.displacementsLoading = true;
      result = await result;
      state.displacementsLoading = false;
      commit("displacementsSuccess", result.data);
      return null;
    } catch (e) {
      state.displacementsLoading = false;
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },
  getDisplacementsByStatus: async ({ commit }, args) => {
    try {
      if (!args.robotId) return;

      let result = MissionService.displacementsByStatus(
        args.robotId,
        args.date_begin,
        args.date_end
      );
      state.displacementsByStatusLoading = true;
      result = await result;
      state.displacementsByStatusLoading = false;
      commit("displacementsByStatusSuccess", result.data);
      return null;
    } catch (e) {
      state.displacementsByStatusLoading = false;
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },
};

const mutations = {
  metricsSuccess(state, data) {
    state.metrics = data;
  },
  displacementsSuccess(state, data) {
    state.displacements = data;
  },
  displacementsByStatusSuccess(state, data) {
    state.displacementsByStatus = data;
  },

  clearLastEventsSuccess(state, payload) {
    state.lastEvents = payload;
  },

  lastUpdatesLoadingSuccess(state) {
    state.lastUpdatesLoading = true;
  },

  moreEventsSuccess(state, payload) {
    state.lastUpdatesLoading = false;
    let id = payload.uuid;
    let data = payload.data;
    let events = [];
    if (data.next) {
      if (
        data.next.split("://")[0] == "http" &&
        data.next.split("://")[1].split("/")[0] != "localhost:8000"
      )
        data.next = "https" + data.next.substring(4);
      events = events.concat({
        uuid: data.next,
        isNotEvent: true,
        content: "Get More Events",
      });
    }
    data.executions.forEach((execution) => {
      execution.events.forEach((event) => {
        event.content = JSON.parse(event.content);
        if (state.lastEvents.find((element) => element.uuid === event.uuid))
          return;
        events.push({
          ...event,
          mission: execution.mission,
          execution: execution.uuid,
          result_status: execution.result_status,
        });
      });
    });
    events = events.sort(
      (a, b) =>
        new Date(a.datetime_executed).getTime() -
        new Date(b.datetime_executed).getTime()
    );
    let index = state.lastEvents.findIndex((elem) => elem.uuid === id);
    let second = state.lastEvents.slice(index + 1);
    state.lastEvents.splice(index);
    state.lastEvents = state.lastEvents.concat(events).concat(second);
  },

  lastUpdatesSuccess(state, data) {
    state.lastUpdatesLoading = false;
    let events = _.cloneDeep(state.lastEvents);
    let missionsReturned = [];
    if (data.next) {
      if (
        data.next.split("://")[0] == "http" &&
        data.next.split("://")[1].split("/")[0] != "localhost:8000"
      )
        data.next = "https" + data.next.substring(4);
      state.lastEvents = state.lastEvents.concat({
        uuid: data.next,
        isNotEvent: true,
        content: "Get More Events",
      });
    }
    data.executions.forEach((execution) => {
      execution.events.forEach((event) => {
        if (!missionsReturned.find((elem) => elem == execution.mission))
          missionsReturned.push(execution.mission);
        event.content = JSON.parse(event.content);
        if (state.lastEvents.find((element) => element.uuid === event.uuid))
          return;
        events.push({
          ...event,
          mission: execution.mission,
          execution: execution.uuid,
          result_status: execution.result_status,
        });
      });
    });
    events = events.sort(
      (a, b) =>
        new Date(b.datetime_executed).getTime() -
        new Date(a.datetime_executed).getTime()
    );
    state.lastEvents = events;

    missionsReturned.forEach((m) => {
      let lastEventMission = events.find((e) => e.mission === m);
      if (lastEventMission) {
        let mission = state.missions.find((mission) => mission.uuid === m);
        if (mission) {
          mission.lastEvent = _.cloneDeep(lastEventMission);
        }
      }
    });
  },

  makeMissionCopySuccess(state, copy) {
    if (!copy) copy = state.missions;
    state.missionsCopy = _.cloneDeep(copy);
  },

  listByRobotIdLoading(state, loading) {
    state.missionsLoading = loading;
  },

  listByRobotIdSuccess(state, data) {
    data.forEach((m) => {
      try {
        m.steps = JSON.parse(m.steps);
      } catch {
        m.steps = [];
      }
    });
    state.missions = data;
    state.missionsCopy = _.cloneDeep(state.missions);
  },

  createSuccess(state, payload) {
    try {
      payload.data.steps = JSON.parse(payload.data.steps);
    } catch {
      payload.data.steps = [];
    }
    let missions_copy = _.cloneDeep(state.missionsCopy);
    missions_copy[payload.index] = payload.data;
    state.missionsCopy = missions_copy;
    missions_copy = _.cloneDeep(state.missions);
    missions_copy.push(_.cloneDeep(payload.data));
    state.missions = missions_copy;
  },

  updateSuccess(state, payload) {
    try {
      payload.steps = JSON.parse(payload.steps);
    } catch {
      payload.steps = { error: "conversion to json failed" };
    }

    let missions_copy = _.cloneDeep(state.missionsCopy);
    let index = missions_copy.findIndex(
      (mission) => mission.uuid === payload.uuid
    );
    if (index > -1) {
      missions_copy[index] = payload;
      state.missionsCopy = missions_copy;
    }
    missions_copy = _.cloneDeep(state.missions);
    index = missions_copy.findIndex((mission) => mission.uuid === payload.uuid);
    if (index > -1) {
      missions_copy[index] = payload;
      state.missions = _.cloneDeep(missions_copy);
    }
  },

  deleteSuccess(state, id) {
    let missions_copy = _.cloneDeep(state.missionsCopy);
    let index = missions_copy.findIndex((mission) => mission.uuid === id);
    if (index > -1) {
      missions_copy.splice(index, 1);
      state.missionsCopy = missions_copy;
    }
    missions_copy = _.cloneDeep(state.missions);
    index = missions_copy.findIndex((mission) => mission.uuid === id);
    if (index > -1) {
      missions_copy.splice(index, 1);
      state.missions = missions_copy;
    }
  },

  executionStartedSuccess(state, payload) {
    if (payload) state.executionStarted = _.cloneDeep(payload);
    else state.executionStarted = null;
  },

  executionSuccess(state, payload) {
    state.execution = payload;
  },

  cancelSuccess() {},
};

const missions = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default missions;
export { missions };
