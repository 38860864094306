<template>
  <container
    id="teleop"
    title="Teleoperation"
    variant="m-0 p-0 col-xl-12"
    :show_header="show_header"
  >
    <template v-slot:header>
      <span
        class="mt-1 text-truncate"
        style="flex: 1; font-size: 20px; width: 8em"
      >
        Teleoperation
      </span>

      <button
        class="btn btn-danger btn-sm mt-1 mr-3 pt-0 pb-0"
        style="height: 25px"
        v-if="dataChannelEnable"
        @click="handleDataChannelBtn"
      >
        LOCK
      </button>
      <button
        class="btn btn-success btn-sm mt-1 mr-3 pt-0 pb-0"
        style="height: 25px"
        v-if="!dataChannelEnable"
        @click="handleDataChannelBtn"
      >
        UN-LOCK
      </button>

      <button
        class="btn btn-primary btn-sm mt-1 pt-0 pb-0"
        style="height: 25px"
        v-if="!connected"
        @click="handleConnect"
      >
        Connect
      </button>
      <button
        class="btn btn-danger btn-sm mt-1 pt-0 pb-0"
        style="height: 25px"
        v-else
        @click="handleDisconnect"
      >
        <i
          aria-hidden="true"
          id="spinner"
          v-if="disconnecting"
          class="fas fa-spin fa-spinner"
        ></i>
        Disconnect
      </button>
      <button
        class="btn btn-secondary btn-sm mt-1 pt-0 pb-0 ml-3"
        style="height: 25px"
        v-if="!isMaximazed"
        @click="handleMaximize"
      >
        <i class="fas fa-window-maximize"></i>
      </button>
      <button
        class="btn btn-secondary btn-sm mt-1 pt-0 pb-0 ml-3"
        style="height: 25px"
        v-else
        @click="handleMaximize"
      >
        <i class="fas fa-window-minimize"></i>
      </button>
    </template>
    <template v-slot:body>
      <div class="col-12 row ml-0 p-0">
        <div
          class="row col-12 p-0 m-0"
          @mouseover="topic_show = true"
          @mouseout="topic_show = false"
        >
          <div class="col-12 p-0 m-0" id="zone">
            <video id="remote-video" autoplay="true" muted="false"></video>
          </div>
          <div class="col-12 position-absolute">
            <div style="width: 100%; background-color: #850;"></div>

            <transition name="fade">
              <div
                class="video-header"
                v-show="topic_show"
                @click="handleTopClick(0)"
              >
                <h5 class="mt-2">
                  <b>{{ subscribeTopic }}</b>
                </h5>
              </div>
            </transition>
          </div>
        </div>
        <hr />
      </div>
    </template>
    <template v-slot:modal-title>
      Settings - Teleop
    </template>
    <template v-slot:modal-body>
      <div class="modal-body mt-0 pt-0">
        <p>Define topic names</p>
        <div
          class="mt-0 mb-2 list-bar"
          v-for="(topic, index) in cameraListCopy"
          :key="index"
        >
          <div class="header">
            <span class="preappend">
              <b-form-input
                type="text"
                v-model="topic.name"
                v-b-tooltip.hover
                title="Edit topic"
                placeholder="Name"
              >
              </b-form-input>
            </span>
            <b-button
              variant="outline-primary"
              v-b-tooltip.hover
              title="Use it."
              @click="subscribeTopicCopy = topic.name"
            >
              <i
                class="fas fa-check"
                v-if="topic.name == subscribeTopicCopy"
              ></i>
              <i class="far fa-square" v-else></i>
            </b-button>
          </div>
        </div>
        <div class="mt-3"></div>
        <p>Select available channels</p>
        <div class="mt-0 mb-2 list-bar">
          <div class="header">
            <span class="preappend" style="height: 3.13em">
              Video Stream Channel
            </span>
            <b-button
              variant="outline-primary"
              v-b-tooltip.hover
              title="Use it."
            >
              <i class="fas fa-check" v-if="true"></i>
              <i class="far fa-square" v-else></i>
            </b-button>
          </div>
        </div>
        <div class="mt-0 mb-2 list-bar">
          <div class="header">
            <span class="preappend" style="height: 3.13em">
              Data Channel
            </span>
            <b-button
              variant="outline-primary"
              v-b-tooltip.hover
              title="Use it."
              @click="handleDataChannelBtn"
            >
              <i class="fas fa-check" v-if="dataChannelEnable"></i>
              <i class="far fa-square" v-else></i>
            </b-button>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <div class="modal-footer">
        <div style="width: 100%">
          <div class="float-left">
            <b-button
              variant="outline-primary"
              v-b-tooltip.hover
              title="New image topic."
              @click="configAdd()"
            >
              <i class="fas fa-plus"></i>
            </b-button>
          </div>
          <div class="float-right">
            <b-button
              variant="primary"
              class="mr-1"
              v-if="configHasChanged"
              @click="configSave()"
            >
              Save
            </b-button>
            <b-button variant="danger" @click="configClose()">
              Close
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </container>
</template>
<script>
import TokenService from "@/services/storage.service";
import Container from "./Container.vue";
import _ from "lodash";
export default {
  components: {
    container: Container,
  },
  props: {
    robotId: String,
  },
  data() {
    return {
      conn: null,
      connected: false,

      wsEndpoint: `wss://s83w778yf3.execute-api.us-east-1.amazonaws.com/v1?token=${TokenService.getToken()}&robotId=${
        this.robotId
      }`,

      subscribeTopic: "/camera1",
      subscribeTopicCopy: null,
      cameraList: [{ name: "/camera1" }, { name: "/camera2" }],
      cameraListCopy: null,
      topic_show: false,
      isMaximazed: false,

      show_header: true,
      dataChannelEnable: false,
      dataChannelEnableCopy: false,

      linear_max_vel: 1.0,
      angular_max_vel: -1.5,
      twist: {
        linear: {
          x: 0,
          y: 0,
          z: 0,
        },
        angular: {
          x: 0,
          y: 0,
          z: 0,
        },
      },

      poll: null,
      pollInterval: 50,
      joystick: null,
      disconnecting: false,
    };
  },

  created() {
    this.wsCreateConnection();
    this.loadSettings();
  },

  mounted() {},

  computed: {
    configHasChanged() {
      if (
        this.subscribeTopicCopy != this.subscribeTopic ||
        this.dataChannelEnableCopy != this.dataChannelEnable
      )
        return true;
      return !_.isEqual(this.cameraList, this.cameraListCopy);
    },
  },
  methods: {
    handleDataChannelBtn() {
      this.dataChannelEnable = !this.dataChannelEnable;
      if (
        this.dataChannelEnable == false &&
        this.conn &&
        this.conn.dataChannel
      ) {
        this.conn.closeDataChannel();
      } else if (this.dataChannelEnable && this.conn) {
        this.conn.setDataChannel();
      }
    },
    handleDataChannel() {
      this.joystick = window.nipplejs.create({
        zone: document.getElementById("teleop"),
        color: "#f40",
        mode: "static",
        dynamicPage: true,

        position: { left: "calc(100% - 80px)", top: "calc(100% - 80px)" },
      });

      this.joystick.on("move", (evt, data) => {
        this.handleTopClick(window.innerHeight);
        this.twist.linear.x = data.vector.y * this.linear_max_vel;
        this.twist.angular.z = data.vector.x * this.angular_max_vel;
      });

      this.joystick.on("shown", () => {
        this.poll = setInterval(() => {
          if (this.conn && this.conn.dataChannel) {
            this.conn.sendMessage(JSON.stringify(this.twist));
          }
        }, this.pollInterval);
      });

      this.joystick.on("end", () => {
        this.twist.linear.x = 0;
        this.twist.angular.z = 0;
        clearInterval(this.poll);
        if (this.conn && this.conn.dataChannel) {
          this.conn.sendMessage(JSON.stringify(this.twist));
        }
      });
    },
    wsCreateConnection() {
      if (!this.conn) {
        console.log(`wsCreateConnection ${this.dataChannelEnable}`);
        this.conn = window.WebrtcRos.createConnection(
          this.wsEndpoint,
          {
            iceServers: [
              {
                urls: "stun:stun1.l.google.com:19302",
              },
              {
                urls: "turn:turn.sara.synkar.com:3478",
                username: "synkar",
                credential: "Synkar123#",
              },
            ],
            onDataChannelOpen: () => {
              console.log("data channel opened");
              this.handleDataChannel();
            },
            onDataChannelClose: () => {
              if (this.joystick) this.joystick.destroy();
              this.joystick = null;
            },
          },
          false
        );
        this.conn.onConfigurationNeeded = this.onConfigurationNeeded;
      }
    },

    loadSettings() {
      var camera = "/camera1";
      if (typeof Storage !== "undefined") {
        let camera_selected = localStorage.getItem("cameraSelected");
        if (camera_selected) camera = camera_selected;
        this.subscribeTopic = camera;

        let cameraList = localStorage.getItem("cameraList");
        console.log(cameraList);
        if (cameraList) this.cameraList = JSON.parse(cameraList);

        this.dataChannelEnable =
          localStorage.getItem("dataChannelEnable") == "true";
      }

      this.dataChannelEnableCopy = this.dataChannelEnable;

      this.cameraListCopy = _.cloneDeep(this.cameraList);
      this.subscribeTopicCopy = this.subscribeTopic;
    },

    onConfigurationNeeded() {
      let config = {};
      config.video = {
        id: "subscribed_video",
        src: "ros_image:" + this.subscribeTopic,
      };
      let self = this;
      this.conn.addRemoteStream(config).then(function(event) {
        self.connected = true;
        window.document.getElementById("remote-video").srcObject = event.stream;

        event.remove.then(function() {
          self.connected = false;
          window.document.getElementById("remote-video").srcObject = null;
        });
      });

      this.conn.sendConfigure();
    },

    handleConnect() {
      if (this.conn) {
        this.conn.connect(this.dataChannelEnable);
      }
    },

    handleDisconnect() {
      if (this.conn) {
        this.disconnecting = true;
        this.conn.close();
        setTimeout(() => {
          this.connected = false;
          this.disconnecting = false;
        }, 3000);
      }
    },

    handleTopClick(y) {
      if (this.isMaximazed) {
        if (y / window.innerHeight < 0.4) {
          this.show_header = true;
        } else {
          this.show_header = false;
        }
      }
    },

    handleMaximize() {
      if (
        window.document
          .getElementById("teleop")
          .classList.contains("expand-card")
      ) {
        window.document
          .getElementById("teleop")
          .classList.remove("expand-card");
        this.isMaximazed = false;
        this.show_header = true;
      } else {
        window.document.getElementById("teleop").classList.add("expand-card");
        this.isMaximazed = true;
        this.show_header = false;
      }
    },

    configClose() {
      this.$bvModal.hide("modal-teleop");
    },

    configAdd() {
      this.cameraListCopy.push({ name: "" });
    },

    configSave() {
      if (typeof Storage !== "undefined") {
        localStorage.setItem("cameraList", JSON.stringify(this.cameraListCopy));
        localStorage.setItem("cameraSelected", this.subscribeTopicCopy);
        localStorage.setItem("dataChannelEnable", this.dataChannelEnable);
      }

      this.cameraList = _.cloneDeep(this.cameraListCopy);
      this.subscribeTopic = this.subscribeTopicCopy;
      this.dataChannelEnableCopy = this.dataChannelEnable;

      this.$bvModal.hide("modal-teleop");
    },
  },

  watch: {},
};
</script>
<style scoped>
#remote-video {
  background-color: rgb(250, 250, 250);
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.btn-header {
  width: 100%;
  opacity: 0.8;
  border-radius: 4px 4px 0px 0px;
}
.video-header {
  height: 20vh;

  text-shadow: 0px 0px 2px #eee;
}
.list-bar {
  border: 1px solid #ced4da;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.list-bar .header {
  display: flex;
}
.list-bar .header span {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
}
.list-bar .header .preappend {
  flex: 1;
  border: none;
}
.list-bar .header .btn {
  border: none;
}
.list-bar .header input {
  padding-left: 0.5em;
  background: none;
  border: none;
}
.modal-footer .confirm-text {
  margin-bottom: 0;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 3em;
  color: #495057;
  text-align: center;
  white-space: nowrap;
}
.modal-footer .confirm-content {
  width: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
