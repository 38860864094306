import ApiService from "./_api.service";

const TelemetryService = {
  isOnline: function(id) {
    if (!id) return;
    return ApiService.get(`/v1/telemetry/${id}/isonline/`);
  },

  listDiagnosticGte(robotId, gte, limit = 10, lastKey = null) {
    if (!robotId) return;

    let url = `/v1/telemetry/${robotId}/diagnostics/?limit=${limit}&gte=${gte}`;

    if (lastKey) {
      url += `&lastKey=${lastKey}`;
    }

    return ApiService.get(url);
  },

  listDiagnostic(robotId, limit = 10, lastKey = null) {
    if (!robotId) return;

    let url = `/v1/telemetry/${robotId}/diagnostics/?limit=${limit}`;

    if (lastKey) {
      url += `&lastKey=${lastKey}`;
    }

    return ApiService.get(url);
  },
};

export default TelemetryService;
export { TelemetryService };
