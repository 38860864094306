<template>
  <div v-if="showPage" class="content">
    <div class="container ">
      <div class="row justify-content-md-center">
        <div class="col-12 col-md-8">
          <b-card no-body>
            <b-card-header>
              <h2>Login with SARA IAM</h2>
            </b-card-header>
            <b-card-body>
              <div class="row">
                <div class="col-12">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text input-pwb">
                        <span
                          :class="client_id ? 'text-success' : ''"
                          v-if="!loading"
                        >
                          <i
                            style="font-size:24px"
                            aria-hidden="true"
                            id="spinner"
                            class="fas fa-briefcase"
                          ></i>
                        </span>
                        <i
                          style="font-size:24px"
                          aria-hidden="true"
                          id="spinner"
                          class="fas fa-spin fa-spinner"
                          v-else
                        ></i>
                      </span>
                    </div>
                    <b-input
                      placeholder="Type slug or client id"
                      v-model="clientSlug"
                      @keyup="handleKeyup"
                    ></b-input>
                  </div>
                  <span v-if="error && clientSlug" class="text-danger">
                    {{ error }}
                  </span>
                </div>
                <div class="col-12 mt-3">
                  <div class="float-right w-100" style="text-align: right">
                    <b-button
                      class="pl-5 pr-5 col-12 col-sm-auto"
                      @click="handleNext"
                      >Next</b-button
                    >
                  </div>
                </div>
              </div>
            </b-card-body>
            <b-card-footer>
              <div class="col-12">
                <span class="float-right">
                  <a class="primary" href="mailto:fellipe.adorno@synkar.com">
                    Need help
                  </a>
                </span>
              </div>
            </b-card-footer>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TokenService from "@/services/storage.service.js";
import ClientsService from "@/services/clients.service.js";
import ApiService from "@/services/_api.service.js";
import _ from "lodash";
export default {
  name: "Login",
  data() {
    return {
      access_token: null,
      showPage: false,
      loading: false,
      client_id: null,
      clientSlug: null,
      error: null,
    };
  },
  created() {
    const saved = TokenService.getClientSlug();
    if (saved) {
      this.clientSlug = saved;
      this.handleInput(this.clientSlug);
    }
    this.access_token = location.hash.substring(
      location.hash.search("access_token"),
      location.hash.length
    );
    this.access_token = this.access_token.substring(
      13,
      this.access_token.search("&")
    );
    if (this.access_token) {
      TokenService.saveToken(this.access_token);
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 200);
    } else {
      this.showPage = true;
    }
  },
  methods: {
    handleNext() {
      if (this.client_id) {
        TokenService.saveClientSlug(this.clientSlug);
        setTimeout(() => {
          ApiService.redirectToLogin(this.client_id);
        }, 500);
      } else {
        this.error = "Type a valid client!";
      }
    },
    handleInput: _.debounce(async function(value) {
      try {
        this.client_id = null;
        this.error = null;
        this.loading = true;
        let result = await ClientsService.slug(value);
        this.client_id = result.data.aws_cognito_client_id;
      } catch (e) {
        this.error = "Client not found!";
      }
      this.loading = false;
    }, 300),
    handleKeyup(event) {
      this.handleInput(event.target.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  background-color: #2c3e50ea;
  height: 100vh;
  width: 100%;
  padding: 30px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;

  .container {
    .card {
      margin-top: 200px;
      width: 100%;
      background-color: #f6f7f8;

      .card-footer {
        -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
        box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
        padding: 0.8rem 2rem 0.8rem 2rem;
      }
      .card-header {
        background-color: initial !important;
        border: none !important;
        padding: 3rem 3rem 0rem 3rem;

        h2 {
          color: #ff8d72;
        }
      }
      .card-body {
        padding: 2rem 3rem 3rem 3rem;
      }
      input {
        height: 3rem;
      }
      .btn {
        background: #d35e26;
        background-color: rgb(211, 94, 38);
        background-position-x: 0%;
        background-position-y: 0%;
        background-image: none;
        background-size: auto;
        background-image: -webkit-gradient(
          linear,
          right top,
          left bottom,
          from(#d35e26),
          color-stop(#f6b26b),
          to(#d35e26)
        );
        background-image: linear-gradient(
          to bottom left,
          #d35e26,
          #f6b26b,
          #d35e26
        );
        background-size: 210% 210%;
        background-position: 100% 0;
        background-color: #d35e26;
        -webkit-transition: all 0.15s ease;
        transition: all 0.15s ease;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: #fff;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
      &:hover {
        -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
        transform: translateY(-1px);
        -webkit-transform: translateY(-1px);
      }
    }
  }
}
</style>
