import BucketsService from '@/services/buckets.service'

const state = {
    buckets: [],
    bucket: null,

    listBucketsRunning: false,
    retrieveBucketRunning: false,
    
}

const getters = {}

const actions = {

    listBuckets: async ({ commit }, args) => {
        try {
            let results = BucketsService.list(args)
            commit('listBucketsRunning', true)
            results = await results
            commit('listBucketsSuccess', results.data)
            return null
        } catch(e) {
            commit('listBucketsRunning', false)
            if (e && e.response) return e.response.status
            else return 500
        }
    },


    retrieveBucket: async ({ commit }, uuid) => {
        try {
            let results = BucketsService.retrieve(uuid)
            commit('retrieveBucketRunning', true)
            results = await results
            commit('retrieveBucketSuccess', results.data)
            return null
        } catch (e) {
            commit('retrieveBucketRunning', false)
            if (e && e.response) return e.response.status
            else return 500
        }
    }
}

const mutations = {
    listBucketsSuccess(state, result) {
        state.buckets = result
        state.listBucketsRunning = false
    },

    listBucketsRunning(state, bool=false) {
        state.listBucketsRunning = bool
    },
    
    
    retrieveBucketSuccess(state, data) {
        state.bucket = data
        state.retrieveBucketRunning = false
    },

    retrieveBucketRunning(state, bool=false) {
        state.retrieveBucketRunning = bool
    }
}

const buckets = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default buckets
export { buckets }