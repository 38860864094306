<template>
    <div class="panel">
    <div id="scrollspy-events" class="ml-0" :style="style">
        <div v-if="!lastEvents || lastEvents.length <= 0" style="text-align: center" class="mt-3">
        There is no events from mission executions in {{convertDatetime(datetimeLast)}} up to now!
        </div>
        <ul class="timeline" v-else>
            <li v-for="event in lastEvents" :key="event.uuid" class="timeline-item mb-2 mr-1">
                <div :class="`d-none d-md-block timeline-badge ${checkEventType(event.content, event.result_status)}`">
                </div>
                <transition name="slide-fade" appear>
                    <div class="timeline-panel ml-2 ml-md-5">
                        <div class="timeline-heading">
                            <h6 class="timeline-title">
                                <b-badge :variant="checkEventType(event.content, event.result_status)">
                                    {{getNameMissionById(event.mission)}}
                                </b-badge>
                            </h6>
                            <div class="timeline-panel-controls">
                            
                                <div class="timestamp">
                                    <small class="text-muted">{{ event.datetime_executed }}</small>
                                </div>
                            </div>
                        </div>
                        <div class="timeline-body">{{event.content.message ? event.content.message: `${event.content.status} step ${event.content.step}`}}</div>
                    </div>
                </transition>
            </li>
        </ul>
        <span id="bottom"></span>
    </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: "MissionEvents",
  components: {
  },
  props: {
    robotId: String,
    height: {
        type: Number,
        default: 500
    }
  },

  data: function() {
      return {
          test: false
      }
  },
  computed: {
    ...mapState({
      missions: state => state['missions'].missions,
      lastEvents: state => state['missions'].lastEvents,
      lastUpdatesLoading: state => state['missions'].lastUpdatesLoading // TODO: Update card color when new event becomes
    }),
    style() {
        return "height: "+this.height+"px; font-size: 12px"
    },
    datetimeFirst() {
      if(!this.lastEvents || this.lastEvents.filter(e=>!e.isNotEvent).length <= 0) {
        var d = new Date()
        d.setHours(0,0,0,0)
        return d.toISOString()
      }
      return this.lastEvents.filter(e=>!e.isNotEvent)[0].datetime_created
    },
    datetimeLast() {
      //return "2021-01-17T01:44:00.085807Z"
      
      if(!this.lastEvents || this.lastEvents.filter(e=>!e.isNotEvent).length <= 0) {
        var d = new Date()
        d.setHours(0,0,0,0)
        return d.toISOString()
      }
      return this.lastEvents.filter(e=>!e.isNotEvent)[this.lastEvents.filter(e=>!e.isNotEvent).length - 1].datetime_created
    },
  },
  methods: {
    ...mapActions('missions', {
      clearLastEvents: 'clearLastEvents',
      moreEvents: 'moreEvents'
    }),
    beforeEnter: function (el) {
        console.log(el);
    },

    convertDatetime(datetime) {
        let date = new Date(datetime)
        return date.toLocaleDateString()+ " "+date.toLocaleTimeString()
    },

    checkEventType(content, result_status) {
      if(!content) return ''
      let result = ''
      switch(content.status) {
        case "done":
          switch(result_status) {
            case 3:
              result += 'success'
              break
            case 4:
              result += 'danger'
              break
            case 5:
              result += 'danger'
              break
            default:
              result += 'warning'
              break
          }
          break
        case "inactive":
          result += 'secondary'
          break;
        default:
          result += 'info'

      }
      return result
    },
    getNameMissionById(id) {
      let mission = this.missions.find(mission=>mission.uuid === id)
      if(mission)  return mission.name
      else return "undefined"
    },
  }
}
</script>
<style lang="scss" scoped>
.panel {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
#scrollspy-events {
  width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box; /* So the width will be 100% + 17px */
}

$medium: 768px;
.timeline {
    list-style: none;
    padding: 10px 0 2px;
    position: relative;
    
    @media screen and (max-width: $medium) {
      padding-top: 0.5rem !important;
    }

    &:before {

      @media screen and (min-width: $medium) {
        background-color: #eee;
        bottom: 0;
        content: " ";
        left: 15px;
        margin-left: -1.5px;
        position: absolute;
        top: 0;
        width: 3px;
      }
    }

    > li {
        margin-bottom: 20px;
        position: relative;

        &:before,
        &:after {
            content: " ";
            display: table;
        }

        &:after {
            clear: both;
        }

        > .timeline-panel {
          background-color: #fff;
            border-radius: 5px;
            border: 1px solid #d4d4d4;
            box-shadow: 0 1px 2px rgba(100, 100, 100, 0.2);
            margin-left: 50px;
            padding: 20px;
            position: relative;
          
          .timeline-heading {
            .timeline-panel-controls {
              position: absolute;
              right: 8px;
              top: 5px;
              
              .timestamp {
                display: inline-block;
              }
              
              .controls {
                display: inline-block;
                padding-right: 5px;
                border-right: 1px solid #aaa;
                
                a {
                  color: #999;
                  font-size: 11px;
                  padding: 0 5px;
                  
                  &:hover {
                    color: #333;
                    text-decoration: none;
                    cursor: pointer;
                  }
                }
              }
              
              .controls + .timestamp {
                padding-left: 5px;
              }
            }
          }
        }

        .timeline-badge {
            background-color: #999;
            border-radius: 50%;
            color: #fff;
            font-size: 1.4em;
            height: 20px;
            left: 30px;
            line-height: 52px;
            margin-left: -25px;
            position: absolute;
            text-align: center;
            top: 21px;
            width: 20px;
            z-index: 100;
        }

        .timeline-badge + .timeline-panel {

          @media screen and (min-width: $medium) {
            &:before {
                border-bottom: 15px solid transparent;
                border-left: 0 solid #ccc;
                border-right: 15px solid #ccc;
                border-top: 15px solid transparent;
                content: " ";
                display: inline-block;
                position: absolute;
                left: -15px;
                right: auto;
                top: 16px;
            }

            &:after {
                border-bottom: 14px solid transparent;
                border-left: 0 solid #fff;
                border-right: 14px solid #fff;
                border-top: 14px solid transparent;
                content: " ";
                display: inline-block;
                position: absolute;
                left: -14px;
                right: auto;
                top: 17px;
            }
          }
        }
    }
}

.timeline-badge {
    &.primary {
        background-color: #cce5ff !important;
    }

    &.success {
        background-color: #28a745 !important;
    }

    &.warning {
        background-color: #ffc107 !important;
    }

    &.danger {
        background-color: #dc3545 !important;
    }

    &.info {
        background-color: #17a2b8 !important;
    }
}
.timeline-title {
  margin-top: 0;
  color: inherit;
}

#timeline-header {
    font-size: 20px;
}

.timeline-body {
    > p,
    > ul {
        margin-bottom: 0;
    }

    > p + p {
        margin-top: 5px;
    }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.9s ease;
}
.slide-fade-leave-active {
  transition: all 1.0s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-20px);
  opacity: 0.2;
}

</style>