import ApiService from './_api.service'


const FleetService = {

    list: function() {
        return ApiService.get("/v1/iam/fleets/")
    },

    getById: function(id) {
        if(!id) return
        return ApiService.get(`/v1/iam/fleets/${id}/`)
    },

    listRobotsById: function(id) {
        if(!id) return
        return ApiService.get(`/v1/iam/fleets/${id}/robots/`)
    },

}

export default FleetService
export { FleetService }