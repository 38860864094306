import TelemetryService from "@/services/telemetry.service";
import qte from "quaternion-to-euler";

const state = {
  online: false,
  onlineError: null,

  diagnostic: null,
  diagnosticLoading: false,
  robot_pose: {
    x: 0,
    y: 0,
    yaw: 0,
  },
};

const getters = {};

const actions = {
  isOnline: async ({ commit }, id) => {
    try {
      let results = await TelemetryService.isOnline(id);
      commit("isOnlineSuccess", results.data);
      return null;
    } catch (e) {
      commit("isOnlineError", e);
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  retriveDiagnosticByGteDatetime: async ({ commit }, args) => {
    try {
      let results = TelemetryService.listDiagnosticGte(
        args.robotId,
        args.gte,
        args.limit,
        args.lastKey
      );

      commit("retriveDiagnosticByGteDatetimeLoading");

      results = await results;

      commit("retriveDiagnosticByGteDatetimeSuccess", results.data);
    } catch (e) {
      commit("retriveDiagnosticByGteDatetimeLoading", false);
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },

  retriveDiagnostic: async ({ commit }, args) => {
    try {
      let results = TelemetryService.listDiagnostic(
        args.robotId,
        args.limit,
        args.lastKey
      );

      commit("retriveDiagnosticLoading");

      results = await results;

      commit("retriveDiagnosticSuccess", results.data);
    } catch (e) {
      commit("retriveDiagnosticLoading", false);
      if (e && e.response) return e.response.status;
      else return 500;
    }
  },
};

const parseDiagnostic = (data) => {
  return data.map((item) => {
    delete item.data.Other;
    return item;
  });
};

const mutations = {
  isOnlineSuccess(state, result) {
    state.online = false;
    if (result == "online") {
      state.online = true;
    }
  },

  isOnlineError(state, error) {
    state.onlineError = error;
  },

  retriveDiagnosticByGteDatetimeSuccess(state, data) {
    if (data && data.results.length > 0) state.diagnostic = data.results;
    else state.diagnostic = null;
    state.diagnosticLoading = false;
  },

  retriveDiagnosticSuccess(state, data) {
    if (data && data.results.length > 0) {
      data.results = parseDiagnostic(data.results);
      console.log(data.results);
      state.diagnostic = data.results;
    } else state.diagnostic = null;
    state.diagnosticLoading = false;
  },

  retriveDiagnosticByGteDatetimeLoading(state, bool = true) {
    state.diagnosticLoading = bool;
  },

  retriveDiagnosticLoading(state, bool = true) {
    state.diagnosticLoading = bool;
  },

  robotPoseSuccess(state, data) {
    state.robot_pose.x = data.x;
    state.robot_pose.y = data.y;
    var quaternion = [
      data.orientation.w,
      data.orientation.x,
      data.orientation.y,
      data.orientation.z,
    ];
    state.robot_pose.yaw = (qte(quaternion)[2] * 180) / 3.1415926;
  },
};

const telemetry = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default telemetry;
export { telemetry };
