import ApiService from "./_api.service";

const ClientsService = {
  attachUser: function(user, client) {
    if (!user) return;

    let data = new FormData();
    data.set("user", user);
    if (client) data.set("client", client);

    return ApiService.post(`/v1/iam/clients/attachUser/`, data);
  },
  dettachUser: function(user, client) {
    if (!user) return;

    let data = new FormData();
    data.set("user", user);
    if (client) data.set("client", client);

    let config = {
      data,
    };

    return ApiService.delete(`/v1/iam/clients/attachUser/`, config);
  },

  slug: function(pk) {
    if (!pk) return;

    return ApiService.get(`/v1/iam/slugs/?pk=${pk}`);
  },
};
export default ClientsService;
export { ClientsService };
