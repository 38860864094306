<template>
    <container 
      id="landmark" 
      title="Missões" 
      variant="m-0">
      <template v-slot:header>
        <span class="mt-1 text-truncate" style="flex: 1; font-size: 20px;">Missions</span>
        <button
          class="btn btn-danger btn-sm mt-1 pt-0 pb-0"
          style="height: 25px"
          v-if="executionMissionId"
          @click="cancelMission()"
          >
          Cancel Mission
        </button>
        <button
          class="btn btn-link"
          style="margin-top: -0.15em; font-size: 17px;"
          v-if="false"
          @click="if(!missionsLoading && robotId) refreshMission()"
          >
          <i v-bind:class="{'fas fa-sync-alt': !lastUpdatesLoading, 'fas fa-sync-alt fa-spin': lastUpdatesLoading}"></i>
        </button>
        <button
          :class="{'btn btn-link d-md-none': content=='btn', 'btn btn-primary d-md-none': content!='btn'}"
          style="margin-top: -0.15em; font-size: 17px"
          @click="toggleLogEvents()"
          >
          <i class="fab fa-buffer"></i>
        </button>
      </template>
      <template v-slot:body>
          <div v-if="content=='btn'" style="min-height: 500px" :key="missionContentKey">
            <div class="row  pl-3 pt-3 pb-0 mr-3">
              <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3 pr-0" v-for="mission in missions" :key="mission.uuid">
                <div class="row justify-content-center">
                  <div class="col-12">
                    <!-- TODO: change variant if has lastEvent and lastEvent datetime_created < current_datatime - 5 secs -->
                    <b-button 
                      class="btn p-4 w-100 shadow"
                      v-bind:variant="(mission.lastEvent && missionButtonReset.filter(m => m == mission.uuid).length < 1) ? checkEventType(mission.lastEvent.content, mission.lastEvent.result_status) : 'primary'"
                      @click="execute(mission)"
                      >
                      {{mission.name}}
                      <div v-if="mission.lastEvent && missionButtonReset.filter(m => m == mission.uuid).length < 1">
                        <span style="font-size: 12px; opacity: 0.7">
                        {{getNameMissionById(mission.lastEvent.mission)}}: {{mission.lastEvent.content.message ? mission.lastEvent.content.message: `${mission.lastEvent.content.status} step ${mission.lastEvent.content.step}`}}
                        </span>
                      </div>
                      <div class="spinner" v-if="false">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                      </div>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="mr-1">
            <mission-events></mission-events>
          </div>
      </template>
      <template v-slot:modal-title>
        Settings - Missions
      </template>
      <template v-slot:modal-body>
        <div class="modal-body">
          
          <p>Set mission for each ballon.</p>
          <div
            class="mt-0 mb-2 list-bar"
            v-for="(mission, index) in missionsCopy" 
            :key="mission.uuid">
            <div
              class="header">
              <span 
                class="preappend">
                <b-form-input
                  type="text"
                  v-model="mission.name"
                  v-b-tooltip.hover title="Edit mission's name"
                  placeholder="Name"
                  >
                </b-form-input>
              </span>
              <span>
                {{mission.steps.length}} {{mission.steps.length == 1 ? "step" : "steps"}}
              </span>
              <b-button
                variant="outline-primary"
                v-b-tooltip.hover title="Save settings."
                v-if="configMissionsWhoChanged.indexOf(mission.uuid) > -1"
                @click="configSaveMission(mission, index)"
                >
                <i class="fas fa-spin fa-spinner" v-if="configSaving.indexOf(index) > -1"></i>
                <i class="fas fa-save" v-else></i>
              </b-button>
              <b-button
                variant="outline-primary"
                v-b-tooltip.hover title="Edit mission's steps"
                @click="configExpandSteps(mission.uuid)">
                <i class="fas fa-layer-group"></i>
              </b-button>
              <b-button 
                variant="outline-danger"
                v-b-tooltip.hover title="Remove mission"
                @click="configRemoveMission(index)">
                <i class="fas fa-spin fa-spinner" v-if="configDeleting.indexOf(mission.uuid) > -1"></i>
                <i class="fas fa-trash-alt" v-else></i>
              </b-button>
            </div>
            <div
              class="body"
              v-if="configMissionSelected == mission.uuid">
              <vue-json-editor v-model="mission.steps" :show-btns="false" :expandedOnStart="true"></vue-json-editor>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:modal-footer>
        <div class="modal-footer">
          <div
            class="confirm-content"
            v-if="configConfirmAlert">
            <span class="confirm-text">
            There is changes that has not saved yet. Are you sure?
            </span>
            <div class="float-right">
              <b-button 
                type="button" 
                class="mr-1"
                variant="primary"
                
                @click="configConfirm(true)"
                >
                Yes
              </b-button>
              <button 
                type="button" 
                class="btn btn-secondary"
                @click="configConfirm(false)"
                >
                No
              </button>
            </div>
          </div>
          <div
            v-else>
            <b-button
              variant="primary"
              class="mr-1"
              @click="configNewMission()"
              >
              New Mission
            </b-button>
            <b-button
              variant="danger"
              @click="configClose()"
              >
              Close
            </b-button>
          </div>
        </div>
      </template>

    </container>
</template>

<script>
import Container from './Container.vue'
import vueJsonEditor from 'vue-json-editor'
import Mission from '@/models/mission.model'
import { mapState, mapActions } from 'vuex'

import _ from 'lodash'
import MissionEvents from './MissionEvents.vue'

export default {
  name: "Mission",
  components: {
    Container,
    vueJsonEditor,
    MissionEvents
  },
  props: {
    robotId: String
  },

  data: function() {
    return {
      configMissionSelected: null,
      configConfirmAlert: false,
      configDeleting: [],
      configSaving: [],
      pullingPromise: null,
      content: "btn",
      missionContentKey: 0,
      missionButtonReset: [],
      buttonResetPromise: null
    }
  },
  async created() {
    
    this.$root.$on('scrollspy::activate')
    await this.listMissions(this.robotId)
    //this.pullingPromise = setInterval(this.refreshMission, 2000)
    this.refreshMission()

    this.missions.forEach(m=>this.missionButtonReset.push(m.uuid))

    this.buttonResetPromise = setInterval(() => {
      let buttonReset = []
      this.missions.forEach(mission => {
        if(mission.lastEvent && mission.lastEvent.result_status > 0) {
          if(new Date(mission.lastEvent.datetime_created).getTime() < new Date().getTime() - 5000) {
            buttonReset.push(mission.uuid)
          }
        } else if(mission.lastEvent && mission.lastEvent.result_status < 0) {
          if(new Date(mission.lastEvent.datetime_created).getTime() < new Date().getTime() - 20000) {
            buttonReset.push(mission.uuid)
          }
        }
      })
      this.missionButtonReset = buttonReset
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.pullingPromise)
    clearInterval(this.buttonResetPromise)
  },
  computed: {
    ...mapState({
      missions: state => state['missions'].missions,
      missionsCopy: state => state['missions'].missionsCopy,
      missionsLoading: state => state['missions'].missionsLoading,
      missionExecution: state => state['missions'].execution,
      lastEvents: state => state['missions'].lastEvents,
      lastUpdatesLoading: state => state['missions'].lastUpdatesLoading // TODO: Update card color when new event becomes
    }),

    datetimeLast() {
      //return "2021-01-17T01:44:00.085807Z"
      
      if(!this.lastEvents || this.lastEvents.filter(e=>!e.isNotEvent).length <= 0) {
        var d = new Date()
        d.setHours(0,0,0,0)
        return d.toISOString()
      }
      return this.lastEvents.filter(e=>!e.isNotEvent)[this.lastEvents.filter(e=>!e.isNotEvent).length - 1].datetime_created
    },

    datetimeFirst() {
      if(!this.lastEvents || this.lastEvents.filter(e=>!e.isNotEvent).length <= 0) {
        var d = new Date()
        d.setHours(0,0,0,0)
        return d.toISOString()
      }
      return this.lastEvents.filter(e=>!e.isNotEvent)[0].datetime_created
    },

    executionMissionId() {
      if(!this.missionExecution) return 0
      else return this.missionExecution.mission
    },


    configMissionsWhoChanged() {
      let who_changes = []
      this.missionsCopy.forEach(copy => {
        if(!_.isEqual(copy, this.missions.filter(mission => mission.uuid === copy.uuid)[0])) {
          who_changes.push(copy.uuid)
        }
        if(this.missions.filter(mission => mission.uuid === copy.uuid).length <= 0) {
          who_changes.push(copy.uuid)
        }
      })
      return who_changes
    }

  },
  methods: {
    ...mapActions('missions', {
      makeMissionCopy: 'makeMissionCopy',
      listMissions: 'listByRobotId',
      createMission: 'create',
      deleteMission: 'delete',
      updateMission: 'update',
      executeMission: 'execute',
      pullExecution: 'getExecutionById',
      clearExecution: 'clearExecution',
      cancelMission: 'cancel',
      getLastExecutionsUpdates: 'getLastExecutionsUpdates',
      clearLastEvents: 'clearLastEvents',
      moreEvents: 'moreEvents'
    }),

    toggleLogEvents() {
      this.content = this.content == 'btn' ? 'log' : 'btn' 
      /*if(this.content == 'log')
        setTimeout(() => {
          this.scrollDown(true)
        }, 200)*/
    },

    scrollDown(ignore_proximity=false) {
      let el = document.querySelector("#bottom")
      let scroll = this.$el.querySelector("#scrollspy-events")
      if(el) {
        if(((scroll.scrollTop + scroll.offsetHeight) / scroll.scrollHeight) > 0.96 || ignore_proximity) {
          el.scrollIntoView({
            behavior: 'smooth'
          })
        }
      }
    },

    async refreshMission() {
      await this.getLastExecutionsUpdates({robot_id: this.robotId, datetime: this.datetimeLast})
    },
    checkEventType(content, result_status) {
      if(!content) return ''
      let result = ''
      switch(content.status) {
        case "done":
          switch(result_status) {
            case 3:
              result += 'success'
              break
            case 4:
              result += 'danger'
              break
            case 5:
              result += 'danger'
              break
            default:
              result += 'warning'
              break
          }
          break
        case "inactive":
          result += 'secondary'
          break;
        default:
          result += 'info'

      }
      return result
    },
    checkEventAlertType(content, result_status) {
      return 'ml-2 mt-2 mb-2 alert alert-'+this.checkEventType(content, result_status)
    },

    getNameMissionById(id) {
      let mission = this.missions.find(mission=>mission.uuid === id)
      if(mission)  return mission.name
      else return "undefined"
    },

    async execute(mission) {
      await this.executeMission(mission.uuid)
    },

    /**
    * Config methods
    */

    configExpandSteps(missionId) {
      if(this.configMissionSelected === missionId)
        this.configMissionSelected = null
      else
        this.configMissionSelected = missionId
    },

    configNewMission() {
      let newMission = new Mission()
      newMission.robot = this.robotId
      this.missionsCopy.push(newMission)
    },

    async configSaveMission(mission, index) {
      this.configSaving.push(index)
      let result = null
      if(mission.uuid) {
        result = await this.updateMission(mission)
      } else {
        mission.index = index
        mission.active = true
        result = await this.createMission(mission)
      }
      this.configSaving.splice(this.configSaving.indexOf(index), 1)
      console.log(result);
    },

    async configRemoveMission(index) {
      let mission = this.missionsCopy[index]
      if(mission.uuid) {
        this.configDeleting.push(mission.uuid)
        let result = await this.deleteMission(mission.uuid)
        this.configDeleting.splice(this.configDeleting.indexOf(mission.uuid), 1)
        if(result) {
          return
        }
      }
      this.missionsCopy.splice(index, 1)
    },

    configClose() {
      if(this.configMissionsWhoChanged.length > 0)
        this.configConfirmAlert = true
      else
        this.$bvModal.hide('modal-landmark')
    },

    configConfirm(state) {
      this.configConfirmAlert = false
      if(state) {
        this.$bvModal.hide('modal-landmark')
        this.makeMissionCopy(this.missions)
        this.configMissionSelected = null
      }
      
    }

  },

  watch: {
    lastEvents: function(lastEventCurrent, lastEventOld) {
      //this.scrollDown()
      if(lastEventCurrent.length != lastEventOld.length) {
        this.missionContentKey++
      }
    },
   
  }
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.list-bar {
  border: 1px solid #ced4da;
  background-color: #e9ecef;
  border-radius: .25rem;

}
.list-bar .header {
  display: flex;
}
.list-bar .header span {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
}
.list-bar .header .preappend {
  flex: 1;
  border: none;
}
.list-bar .header .btn {
  border: none;
}
.list-bar .header input {
  padding-left: 0.5em;
  background: none;
  border: none;
}
.modal-footer .confirm-text {
  margin-bottom: 0;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 3em;
  color: #495057;
  text-align: center;
  white-space: nowrap;
}
.modal-footer .confirm-content {
  width: 100%;
}

#scrollspy-events {
  position: relative;
  height: 500px;
  overflow-y: scroll;
}


</style>
