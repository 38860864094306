import ApiService from "./_api.service";

const MissionService = {
  listByRobotId: function(robotId) {
    return ApiService.get("/v1/missions/?robot=" + robotId);
  },

  getById: function(uuid) {
    if (!uuid) return;
    return ApiService.get(`/v1/missions/${uuid}/`);
  },

  create: function(model) {
    let data = new FormData();
    Object.keys(model).forEach((field) => {
      try {
        if (field == "steps") data.set(field, JSON.stringify(model[field]));
        else if (model[field] != null) data.set(field, model[field]);
      } catch {
        data.set(field, null);
      }
    });
    return ApiService.post(`/v1/missions/`, data);
  },

  update: function(uuid, model) {
    if (!uuid || !model) return;

    let data = new FormData();
    Object.keys(model).forEach((field) => {
      try {
        if (field == "steps") data.set(field, JSON.stringify(model[field]));
        else if (model[field] != null && field !== "uuid")
          data.set(field, model[field]);
      } catch {
        data.set(field, null);
      }
    });
    return ApiService.patch(`/v1/missions/${uuid}/`, data);
  },

  delete: function(uuid) {
    if (!uuid) return;
    return ApiService.delete(`/v1/missions/${uuid}/`);
  },

  execute: function(uuid) {
    if (!uuid) return;
    return ApiService.post(`/v1/missions/${uuid}/execute/`);
  },

  cancel: function(uuid) {
    if (!uuid) return;
    return ApiService.post(`/v1/missions/${uuid}/cancel/`);
  },

  getExecutionById: function(uuid) {
    if (!uuid) return;
    return ApiService.get(`/v1/missions/execution/${uuid}/`);
  },

  lastUpdatesGT: function(robot_uuid, datetime) {
    if (!robot_uuid || !datetime) return;

    return ApiService.get(
      `/v1/missions/execution/last/?robot=${robot_uuid}&datetime_gt=${datetime}`
    );
  },
  lastUpdatesLTE: function(robot_uuid, datetime) {
    if (!robot_uuid || !datetime) return;

    return ApiService.get(
      `/v1/missions/execution/last/?robot=${robot_uuid}&datetime_lte=${datetime}`
    );
  },

  getByUrl: function(url) {
    return ApiService.get(url);
  },

  metrics: function(robot_uuid, date_begin, date_end) {
    if (!robot_uuid) return;
    const date_begin_q =
      date_begin && date_begin !== "0" ? `&date_begin=${date_begin}` : "";
    const date_end_q =
      date_end && date_end !== "0" ? `&date_end=${date_end}` : "";

    return ApiService.get(
      `/v1/missions/metrics/?robot=${robot_uuid}${date_begin_q}${date_end_q}`
    );
  },

  displacements: function(robot_uuid, date_begin, date_end, result_status) {
    if (!robot_uuid) return;

    const date_begin_q =
      date_begin && date_begin !== "0" ? `&date_begin=${date_begin}` : "";
    const date_end_q =
      date_end && date_end !== "0" ? `&date_end=${date_end}` : "";
    const result_status_q = result_status
      ? `&result_status=${result_status}`
      : "";

    return ApiService.get(
      `/v1/missions/displacements/?robot=${robot_uuid}${date_begin_q}${date_end_q}${result_status_q}`
    );
  },
  displacementsByStatus: function(robot_uuid, date_begin, date_end) {
    if (!robot_uuid) return;

    const date_begin_q =
      date_begin && date_begin !== "0" ? `&date_begin=${date_begin}` : "";
    const date_end_q =
      date_end && date_end !== "0" ? `&date_end=${date_end}` : "";

    return ApiService.get(
      `/v1/missions/displacements_status/?robot=${robot_uuid}${date_begin_q}${date_end_q}`
    );
  },
};

export default MissionService;
export { MissionService };
