import UsersService from '@/services/users.service'

const state = {
    me: null,
    meError: null
}

const getters = {}

const actions = {

    getMe: async ({ commit }) => {
        try {
            let results = await UsersService.me()
            commit('getMeSuccess', results.data)
            return null
        } catch(e) {
            commit('getMeError', e)
            if (e && e.response) return e.response.status
            else return 500
        }
    }
}

const mutations = {
    getMeSuccess(state, result) {
        state.me = result
    },

    getMeError(state, error) {
        state.meError = error;
    }
}

const users = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default users
export { users }