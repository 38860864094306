<template>
  <container id="map2d" variant="col-xl-12 m-0 p-0" modal_size="lg" @onModalOpen="configModalOpened">
    <template v-slot:header>
      <span class="mt-1 text-truncate" style="flex: 1; font-size: 20px;">
        Grid Map
      </span>
      <button
        :class="{'btn btn-link d-md-none': content=='btn', 'btn btn-primary d-md-none': content!='btn'}"
        style="margin-top: -0.15em; font-size: 17px"
        @click="toggleLogEvents()"
        >
        <i class="fab fa-buffer"></i>
      </button>
    </template>
    <template v-slot:body>
      <div v-if="content=='btn'">
        <div id="map" v-on:mousedown="handleMapMouseDown" v-on:mouseup="handleMapMouseUp" v-on:touchstart.passive="handleMapTouchEnter" v-on:touchend.passive="handleMapTouchLeave">
          
                  <!--
          easel-container example:
          A container that is flipped, shrunk by half, and positioned with x,y.
          -->
          <easel-canvas :width="image_size.width" :height="image_size.height" style="background: gray">
            <easel-bitmap
                    :image="mapfile"
                    :alpha="0.8"
                    :cache="false"
                    :x="0"
                    :y="0"
                    :width="100"
                    :key="mapfile"
                    :align="['left','top']"
                >
              </easel-bitmap>
            <easel-container
                  v-if="robot_pose.visible"
                  :x="robot_pose.x"
                  :y="robot_pose.y"
                  :rotation="robot_pose.yaw"
                  :scale="0.08"
              >
              <easel-container
                  :x="-100"
                  :y="-85"
                  :rotation="0"
              >
              <easel-shape
                  form="star"
                  :dimensions="[120, 3, 0]"
                  fill="yellow"
                  :x="370"
                  :y="205"
                  :rotation="180"
                  :alpha="0.9"
              >
              </easel-shape>
              <easel-shape
                    form="rect"
                    :dimensions="[200, 130, 3]"
                    fill="orange"
                    stroke="#ee8900"
                    :x="0"
                    :y="20"
                    :rotation="0"
                ></easel-shape>
              <easel-shape
                    form="rect"
                    :dimensions="[50, 20, 5]"
                    fill="#323232"
                    :x="10"
                    :y="0"
                    :rotation="0"
                ></easel-shape>
              <easel-shape
                    form="rect"
                    :dimensions="[50, 20, 5]"
                    fill="#323232"
                    :x="140"
                    :y="0"
                    :rotation="0"
                ></easel-shape>
              <easel-shape
                    form="rect"
                    :dimensions="[50, 20, 5]"
                    fill="#323232"
                    :x="10"
                    :y="150"
                    :rotation="0"
                ></easel-shape>
              <easel-shape
                    form="rect"
                    :dimensions="[50, 20, 5]"
                    fill="#323232"
                    :x="140"
                    :y="150"
                    :rotation="0"
                ></easel-shape>    
              <easel-shape
                    form="rect"
                    :dimensions="[10, 100, 0]"
                    fill="#333"
                    :x="175"
                    :y="35"
                    :rotation="0"
                ></easel-shape>    
              </easel-container>
            </easel-container>
          </easel-canvas>

        </div>
      </div>
      <div v-else class="mr-1">
        <mission-events></mission-events>
      </div>
    </template>
    <template v-slot:modal-title>
        <h5 class="modal-title">Settings - Map</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="configClose()">
          <span aria-hidden="true">×</span>
        </button>
      
    </template>
    <template v-slot:modal-body>
        <div class="modal-body" style="background: #f2f3f5;">
          <div>
            <label for="bucket_name">
              Select a bucket to choose map files
            </label>
            <b-form-input 
              list="bucket_names" 
              id="bucket_name"  
              :state="config_bucket_name_selected" 
              v-model="config_bucket_name" 
              debounce="500" ></b-form-input>

            <datalist 
              id="bucket_names" 
              v-if="buckets.results && buckets.results.length > 0"
              >
              <option v-for="bucket in buckets.results" :key="bucket.uuid" @change="configHandleBucketName">{{bucket.name}}</option>
            </datalist>

          </div>
          <div v-if="config_bucket_name_selected" class="mt-4">
            <div v-if="!bucketImageFiltered || bucketImageFiltered.length <= 0">
              <span class="text-danger ml-1">
              No image file was found!
              </span>
            </div>
            <div class="config-select-table" v-else>
              <label for="config_bucket_objects">
                Select one image file
              </label>
              <b-table 
                hover 
                selectable
                select-mode="single"
                selected-variant="success"
                :items="bucketImageFiltered"
                :fields="configSelectImageFields"
                ref="selectableImageTable"
                @row-selected="configHandleRowSelectedImage"
                id="config_bucket_objects"
                ></b-table>
            </div>
            <div v-if="!bucketYamlFiltered || bucketYamlFiltered.length <= 0">
              <span class="text-danger ml-1">
              No image config file was found!
              </span>
            </div>
            <div class="config-select-table" v-else>
              <label for="config_bucket_objects_yaml">
                Select image config file
              </label>
              <b-table 
                hover 
                selectable
                select-mode="single"
                selected-variant="success"
                :items="bucketYamlFiltered"
                :fields="configSelectYamlFields"
                ref="selectableYamlTable"
                @row-selected="configHandleRowSelectedYaml"
                id="config_bucket_objects_yaml"
                >
                </b-table>
            </div>
          </div>
        </div>
    </template>
    <template v-slot:modal-footer>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="configClose()">Fechar</button>
            <button type="button" class="btn btn-primary" data-dismiss="modal"
              v-if="config_bucket_name_selected && config_bucket_yaml_name && config_bucket_image_name"
              @click="configSaveSettings()">Salvar</button>
          </div>
    </template>
  </container>
</template>
<script>
import Container from './Container.vue'
import { mapState, mapActions } from 'vuex'
//import _ from 'lodash'

import BucketsService from '@/services/buckets.service'
import MissionEvents from './MissionEvents.vue'

const yaml = require('js-yaml')
export default {
  name: "Map2D",
  components: {
    Container,
    MissionEvents
  },
  props: {
    robotId: String
  },

  data: function() {
    return {
      test: 'nada',
      diff: {},
      content: "btn",
      contentKey: 0,
      mapfile: "",
      connection: null,
      objects: [
        {
          topic: "/synkar/map",
          type: "nav_msgs/OccupancyGrid",
        },
      ],
      width: 2000,
      height: 2000,
      zoomView: null,
      viewer: null,
      cursor: {},

      resolution: 0.1,

      map_center: {
        x: -1375,
        y: -650,
        yaw: 0
      },
      
      configSelectYamlFields: [ 'Name', 'Size'],
      configSelectImageFields: [ 'Name', 'Size'],
      config_bucket_name: "",
      config_bucket_image_name: null,
      config_bucket_yaml_name: null,
      config_bucket_name_selected: null,
      config_bucket_selected: null,

      image_size: {
        width: 1000,
        height: 1000
      }
    }
  },
  created() {
    this.listBuckets();
  },
  
  mounted() {

    const map = localStorage.getItem('map-'+this.robotId)
    if(map) {
      let jsonConfig = JSON.parse(map)
      this.loadFiles(jsonConfig)
    }
    
  },
  computed: {
    ...mapState({
      bucket: state => state['buckets'].bucket,
      buckets: state => state['buckets'].buckets,
      listBucketsRunning: state => state['buckets'].listBucketsRunning,
      robotPose: state => state['telemetry'].robot_pose
    }),

    robot_abs_pose() {
      let pose = this.robotPose;
      pose.x = pose.x / this.resolution;
      pose.y = pose.y / this.resolution;
      return pose
    },

    bucketImageFiltered() {
      if (!this.bucket || !this.bucket.objects) return null;

      return this.bucket.objects
        .filter(obj => obj.Size > 0)
        .filter(obj => obj.Key.endsWith(".png") || obj.Key.endsWith(".pgm") || obj.Key.endsWith(".jpg") || obj.Key.endsWith(".jpeg"))
        .map(obj => {
          return {
            Name: obj.Key,
            Size: this.bytesToSize(obj.Size)
          }
        })
    },
    bucketYamlFiltered() {
      if (!this.bucket || !this.bucket.objects) return null;

      return this.bucket.objects
        .filter(obj => obj.Size > 0)
        .filter(obj => obj.Key.endsWith(".yaml"))
        .map(obj => {
          return {
            Name: obj.Key,
            Size: this.bytesToSize(obj.Size)
          }
        })
    },
    robot_pose() {
      let obj = {
        x: 0,
        y: 0,
        yaw: 0,
        visible: false
      }

      if(this.map_center && this.robot_abs_pose) {
        obj.visible = true;
        obj.x = this.map_center.x + this.robot_abs_pose.x;
        obj.y = this.map_center.y - this.robot_abs_pose.y;
        obj.yaw = this.map_center.yaw - this.robot_abs_pose.yaw;
      }
      return obj;

    }
  },
  methods: {
    ...mapActions('buckets', {
      listBuckets: 'listBuckets',
      retrieveBucket: 'retrieveBucket'
    }),
    toggleLogEvents() {
      this.content = this.content == 'btn' ? 'log' : 'btn';
    },
    bytesToSize(bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        },
    handleMapMouseDown(event) {
      this.cursor = {x: event.layerX, y: event.layerY};
      let content = document.querySelector("#map");
      content.className="cursor-move";
    },

    handleMapTouchEnter(event) {
      this.cursor = {x: event.touches[0].clientX, y: event.touches[0].clientY}
    },

    handleMapTouchLeave(event) {
      let diff = {
        x: this.cursor.x - event.changedTouches[0].clientX,
        y: this.cursor.y - event.changedTouches[0].clientY
      }

      let content = document.querySelector("#map");
      content.scrollLeft += diff.x;
      content.scrollTop += diff.y;
    },

    handleMapMouseUp(event) {
      let diff = {x: this.cursor.x - event.layerX, y: this.cursor.y - event.layerY};

      let content = document.querySelector("#map");
      content.className="cursor-auto";
      content.scrollLeft += diff.x;
      content.scrollTop += diff.y;
    },

    centerRobot() {

    },

    configHandleBucketName(e) {
      console.log(e);
    },

    configClose() {
      this.$bvModal.hide('modal-map2d')
    },

    configHandleRowSelectedYaml(e) {
      if(e.length > 0)
      this.config_bucket_yaml_name = e[0].Name
    },
    configHandleRowSelectedImage(e) {
      if(e.length > 0)
      this.config_bucket_image_name = e[0].Name
    },

    configSaveSettings() {
      this.$bvModal.hide('modal-map2d')

      const bucket = this.buckets.results.filter(bucket => bucket.name == this.config_bucket_name)[0]

      const saveJson = {
        bucket,
        image: this.config_bucket_image_name,
        config: this.config_bucket_yaml_name
      }

      if (typeof (Storage) !== "undefined") {
          localStorage.setItem('map-'+this.robotId, JSON.stringify(saveJson))
      }
      this.loadFiles(saveJson)
    },

    async loadFiles(config) {
      if(!config || !config.bucket || !config.bucket.uuid)
        return false;

      try {


        const fileImage = await  BucketsService.downloadLink(config.bucket.uuid, config.image)
        this.mapfile = fileImage.data

        var img = new Image();
        img.src = fileImage.data;
        img.onload = async () => {
          this.image_size.width = img.width;
          this.image_size.height = img.height;
          var enc = new TextDecoder("utf-8");
          let file = await BucketsService.download(config.bucket.uuid, config.config)
          const configYaml = yaml.load(enc.decode(file.data));
          this.map_center.x = -1 * configYaml.origin[0] / configYaml.resolution
          this.map_center.y = this.image_size.height +  configYaml.origin[1] / configYaml.resolution
          this.resolution = configYaml.resolution
        }


      } catch (err) {
        console.log(err);
      }

    },

    configModalOpened() {
      if (typeof (Storage) !== "undefined") {
        const map = localStorage.getItem('map-'+this.robotId)
        if(map) {
          const json = JSON.parse(map)
          this.config_bucket_name = json.bucket.name

          let yaml_count=0
          let yaml_interval = setInterval(() => {
            if(this.$refs.selectableYamlTable) {
              
              if(this.bucket && this.bucket.objects) {
                const i = this.bucket.objects
                  .filter(obj => obj.Size > 0)
                  .filter(obj => obj.Key.endsWith(".yaml"))
                  .findIndex(obj => obj.Key == json.config)
                this.$refs.selectableYamlTable.selectRow(i)
              }

              clearInterval(yaml_interval)
            }
            if(yaml_count > 200) {
              clearInterval(yaml_interval)
            }
            yaml_count++
          }, 10);
          let image_count=0
          let image_interval = setInterval(() => {
            if(this.$refs.selectableYamlTable) {
              
              if(this.bucket && this.bucket.objects) {
                const i = this.bucket.objects
                  .filter(obj => obj.Size > 0)
                  .filter(obj => obj.Key.endsWith(".png") || obj.Key.endsWith(".pgm") || obj.Key.endsWith(".jpg") || obj.Key.endsWith(".jpeg"))
                  .findIndex(obj => obj.Key == json.image)
                this.$refs.selectableImageTable.selectRow(i)
              }

              clearInterval(image_interval)
            }
            if(image_count > 200) {
              clearInterval(image_interval)
            }
            image_count++
          }, 10);
        }
      }
    }

  },

  watch: {
    config_bucket_name: {
      handler: function(val) {
        if(val) {
          if(this.buckets && this.buckets.results && this.buckets.results.length > 0) {
            this.config_bucket_name_selected = this.buckets.results.filter(bucket => bucket.name == val).length > 0
            if(this.config_bucket_name_selected) {
              this.retrieveBucket(this.buckets.results.filter(bucket => bucket.name == val)[0].uuid)
            }
          }
        } else {
          this.config_bucket_name_selected = null;
        }
        this.listBuckets({name: val})
      }
    },
  }
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

#map {

  cursor: grab;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-user-drag: element;
  height: 600px;

}

#map.cursor-move {
  cursor: move;
}

.config-select-table {
  max-height: 200px;
  overflow-y: scroll;
}

</style>
