import FleetService from '@/services/fleets.service'

const state = {
    fleets: [],
    robots: [],

    listRunning: false,
    listRobotsRunning: false
}

const getters = {}

const actions = {

    cleanRobots: ({commit}) => {
        commit('cleanRobotsSuccess')
        return null
    },

    list: async ({ commit }) => {
        try {
            let results = FleetService.list()
            commit('listRunningSuccess')
            results = await results
            commit('listSuccess', results.data)
            return null
        } catch(e) {
            commit('listRunningError')
            if (e && e.response) return e.response.status
            else return 500
        }
    },

    getById: async ({ commit }, id) => {
        try {
            let results = FleetService.getById(id)
            commit('listRobotsRunningSuccess')
            results = await results
            commit('listRobotsSuccess', results.data)
            return null
        } catch(e) {
            commit('listRobotsRunningError')
            if (e && e.response) return e.response.status
            else return 500
        }
    }
}

const mutations = {
    cleanRobotsSuccess(state) {
        state.robots = []
    },
    listSuccess(state, results) {
        state.fleets = results.results
        state.listRunning = false
    },

    listRobotsSuccess(state, results) {
        state.robots = results.robots
        state.listRobotsRunning = false
    },

    listRunningSuccess(state) {
        state.listRunning = true
    },

    listRobotsRunningSuccess(state) {
        state.listRobotsRunning = true
    },

    listRunningError(state) {
        state.listRunning = false
    },

    listRobotsRunningError(state) {
        state.listRobotsRunning = false
    },
}

const fleets = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default fleets
export { fleets }