<template>
  <div :class="variant">
    <div class="card w-100 shadow" :id="id">
      <transition name="fade">
      <div class="card-header pb-0 pt-1" v-if="show_header">
        <div class="" style="display: flex">
          <slot name="header" v-if="hasHeaderSlot"></slot>
          <span class="mt-1" style="flex: 1; font-size: 20px;" v-else>
            <span class="d-none d-sm-block">
            {{title}}</span>
          </span>
          <button 
            class="btn align-middle"
            style="margin-right: -1em"
            @click="handleOpenModal(modalId)"
            v-if="show_config_btn"
            >
              <i class="fa fa-cog fa-fw" aria-hidden="true" style="font-size: 20px"></i>
          </button>


        </div>
      </div>
      </transition>
      <div class="card-body p-0 m-0">
          <slot name="body"></slot>
      </div>
    </div>
    <b-modal v-bind:id="modalId" :size="modal_variant" hide-footer no-close-on-backdrop>
      <template #modal-header>
        <slot name="modal-title"></slot>
      </template>
      <slot name="modal-body"></slot>
      <slot name="modal-footer"></slot>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "Container",
  props: {
    title: String,
    id: String,
    variant: {
      type: String,
      default: 'col-12'
    },
    modal_variant: {
      type: String,
      default: "md"
    },
    modal_title: String,
    show_header: {
      type: Boolean,
      default: true
    },
    show_config_btn: {
      type: Boolean,
      default: true
    }
  },

  data: function() {
    return {
      modalShow: false,
    }
  },

  computed: {


    modalId() {
      return `modal-${this.id}`;
    },

    modalIdIdentifier() {
      return `#modal-${this.id}`;
    },
    hasHeaderSlot() {
      return !!this.$slots.header
    }

  },
  methods: {
    handleOpenModal(modalId) {
      this.$bvModal.show(modalId)
      this.$emit('onModalOpen', modalId)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.expand-card {
  position: fixed;
  width: 100% !important;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 1000;
}
.expand-card video {
  max-height: 100vh;
  overflow: auto;
  background-color: rgb(40, 40, 40) !important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
