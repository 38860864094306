import Vue from 'vue'
import Vuex from 'vuex'

import fleets from './fleets.module'
import missions from './missions.module'
import telemetry from './telemetry.module'
import users from './users.module'
import buckets from './buckets.module'
import websocket from './websocket.module'

import plugin from '@/plugins/websocket.plugin'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        fleets,
        missions,
        telemetry,
        users,
        buckets,
        websocket,
    },
    plugins: [plugin()]
})
