<template>
  <div id="content">
    <div class="w-100">
      <div class="row m-0 justify-content-between header s-container">
        <div class="col-4 col-md-4 mt-1 mr-xl-0">
          <img src="@/assets/logo1-transparente.png" width="100" class="logo" />
        </div>
        <div
          class="col-12 col-md-8 pr-4 mt-md-2"
          style="text-align: right"
          v-if="me"
        >
          Welcome <b>{{ me.last_name }}, {{ me.first_name }}! </b>
        </div>
        <!--
        <div class="col-4 text-right">
          <button class="btn align-middle" style="display: inline-block; height: 100%;" data-toggle="modal" data-target="#settings"><i class="fa fa-cog fa-fw" aria-hidden="true"
              style="font-size: 22px"></i></button>
        </div>
      --></div>
      <div class="body s-container">
        <div class="row justify-content-left m-0">
          <div class="col-12 col-sm-4 pb-0 mb-2 mb-sm-0">
            <div class="input-group">
              <div class="input-group-prepend" v-if="listFleetsRunning">
                <span class="input-group-text input-pwb">
                  <i
                    style="font-size:24px"
                    aria-hidden="true"
                    id="spinner"
                    class="fas fa-spin fa-spinner"
                  ></i>
                </span>
              </div>
              <select
                class="custom-select form-control"
                v-bind:class="{ 'select-wlb': listFleetsRunning }"
                required
                @change="changeFleet"
              >
                <option value="">Fleets</option>
                <option
                  v-for="fleet in fleets"
                  v-bind:key="fleet.uuid"
                  :value="fleet.uuid"
                  >{{ fleet.name }}</option
                >
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-4 mb-2 mb-sm-0">
            <div class="input-group">
              <div class="input-group-prepend" v-if="listRobotsRunning">
                <span class="input-group-text input-pwb">
                  <i
                    style="font-size:24px"
                    aria-hidden="true"
                    id="spinner"
                    class="fas fa-spin fa-spinner"
                  ></i>
                </span>
              </div>
              <select
                class="custom-select form-control"
                v-bind:class="{ 'select-wlb': listRobotsRunning }"
                required
                @change="changeRobot"
              >
                <option value="">Robots</option>
                <option
                  v-for="robot in robots"
                  v-bind:key="robot.uuid"
                  :value="robot.uuid"
                  >{{ robot.name }}</option
                >
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-3 col-xl-4">
            <b-button
              variant="btn btn-primary"
              v-bind:disabled="!robotId"
              v-if="robotSelected != robotId"
              @click="selectRobot(robotId)"
            >
              <i class="fas fa-spin fa-spinner" v-show="wsConnecting"></i>
              Select</b-button
            >
            <div
              class="d-sm-none d-block float-right mr-2 mt-1"
              v-if="robotSelected"
            >
              <span
                :style="robotOnline ? 'color: #0F0' : 'color: #F00'"
                class="ml-2 ml-md-0"
              >
                Robot is {{ robotOnline ? "online" : "offline" }}!
                <i class="fas fa-globe-americas"></i>
              </span>
            </div>
          </div>
        </div>
        <hr class="solid" />
        <div class="row online-bar d-none d-sm-block m-0" v-if="robotSelected">
          <span
            :style="robotOnline ? 'color: #0F0' : 'color: #F00'"
            class="ml-2 ml-md-0"
          >
            Robot is {{ robotOnline ? "online" : "offline" }}!
            <i class="fas fa-globe-americas"></i>
          </span>
        </div>
        <transition name="slide-fade">
          <div class="row m-0 mb-3" v-show="showPanel && robotSelected">
            <div class="col-12 col-md-8 col-lg-9">
              <b-card no-body>
                <b-tabs card>
                  <b-tab title="Control Panel" active>
                    <b-card-text>
                      <div class="col-xl-12 row m-0 p-0">
                        <div class="col-xl-12 m-0 p-0">
                          <mission
                            :robotId="robotSelected"
                            :key="robotSelected"
                          ></mission>
                        </div>
                        <!--
                        <div class="col-xl-6 m-0 p-0">
                          <rtabmap :robotId="robotSelected" :key="robotSelected"></rtabmap>
                        </div>
                        -->
                      </div>
                      <div class="col-xl-6 m-0 p-0">
                        <!--<map2d :robotId="robotSelected"></map2d>-->
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Metrics">
                    <b-card-text>
                      <div class="col-xl-12 row m-0 p-0">
                        <metrics
                          :robotId="robotSelected"
                          :key="robotSelected"
                        ></metrics>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Map">
                    <b-card-text>
                      <div class="col-xl-12 row m-0 p-0">
                        <map2d
                          :robotId="robotSelected"
                          :key="robotSelected"
                        ></map2d>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Diagnostics">
                    <b-card-text>
                      <div class="col-xl-12 row m-0 p-0">
                        <diagnostics
                          :robotId="robotSelected"
                          :key="robotSelected"
                        ></diagnostics>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Teleop">
                    <b-card-text>
                      <div class="col-xl-12 row m-0 p-0">
                        <teleop
                          :robotId="robotSelected"
                          :key="robotSelected"
                        ></teleop>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card>
            </div>
            <div class="d-none d-md-block col-md-4 col-lg-3">
              <mission-events :height="720"></mission-events>
            </div>
          </div>
        </transition>
      </div>
      <div class="row m-0 mt-0 pt-4 pb-2 footer s-container">
        <div class="col-12 text-center">
          <span style="font-size: 10px;"
            >Synkar Autonomous @ 2020. All rights reserved.</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mission from "@/components/Mission";
//import RTABMap from '@/components/RTABMap'
import Map2D from "@/components/Map2D";
import Metrics from "@/components/Metrics";
import Diagnostics from "@/components/Diagnostics";
import Teleop from "@/components/Teleop";
//import Container from '@/components/Container'
import { mapState, mapActions } from "vuex";
import MissionEvents from "../components/MissionEvents.vue";

//import Fleet from '@/models/fleet.model'

export default {
  name: "Dashboard",
  components: {
    mission: Mission,
    //rtabmap: RTABMap,
    metrics: Metrics,
    diagnostics: Diagnostics,
    map2d: Map2D,
    teleop: Teleop,
    //container: Container,
    MissionEvents,
  },
  data() {
    return {
      robotSelected: null,
      robotId: null,
      pullingPromise: null,
      showPanel: false,
      currentTimestamp: new Date().getTime(),
    };
  },
  beforeCreate() {},
  created() {
    this.getMe();

    this.listFleet();

    setInterval(() => {
      this.currentTimestamp = new Date().getTime();
    }, 1000);
  },
  computed: {
    ...mapState({
      me: (state) => state["users"].me,
      fleets: (state) => state["fleets"].fleets,
      robots: (state) => state["fleets"].robots,
      listFleetsRunning: (state) => state["fleets"].listRunning,
      listRobotsRunning: (state) => state["fleets"].listRobotsRunning,

      wsConnected: (state) => state["websocket"].connected,
      wsConnecting: (state) => state["websocket"].connecting,
      wsLastMessageTimestamp: (state) =>
        state["websocket"].lastMessageTimestamp,
    }),

    robotOnline() {
      return this.currentTimestamp - this.wsLastMessageTimestamp < 100 * 1000;
    },
  },
  methods: {
    ...mapActions("users", {
      getMe: "getMe",
    }),
    ...mapActions("fleets", {
      cleanRobots: "cleanRobots",
      listFleet: "list",
      retrieveFleet: "getById",
    }),
    ...mapActions("missions", {
      clearLastEvents: "clearLastEvents",
    }),
    ...mapActions({
      connect: "connect",
      disconnect: "disconnect",
    }),

    changeFleet(event) {
      clearInterval(this.pullingPromise);
      let fleetId = event.target.value;
      if (fleetId !== "") {
        this.retrieveFleet(fleetId);
      } else this.cleanRobots();
    },

    changeRobot(event) {
      clearInterval(this.pullingPromise);
      let robotId = event.target.value;
      if (robotId !== "") this.robotId = robotId;
    },

    selectRobot(id) {
      if (this.robotSelected !== id) this.clearLastEvents();
      this.robotSelected = id;
      this.showPanel = false;
      this.disconnect();
      setTimeout(() => {
        this.showPanel = true;
      }, 1000);
      this.connect(id);
    },
  },
};
</script>

<style lang="scss">
$breakpoint-tablet: 1200px;
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}
hr {
  @media (min-width: $breakpoint-tablet) {
    margin-left: 1em;
    margin-right: 1em;
  }
}
#content {
  background-color: #f6f7f8;
  border-top: 3px solid #ff8d72;
  .header,
  .footer {
    background-color: #2c3e50ea;
    color: #eee;
  }
  .header {
    height: 85px;
    -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
    .custom-select {
      background-color: #ff8d72 !important;
    }
    @media (min-width: $breakpoint-tablet) {
      height: 65px;
    }
  }
  .footer {
    height: 50px;
  }
  .body {
    min-height: calc(100vh - 138px);
    padding-top: 1rem;
    @media (min-width: $breakpoint-tablet) {
      min-height: calc(100vh - 118px);
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  #content div.s-container {
    padding-left: 250px !important;
    padding-right: 250px !important;
  }
}

.online-bar {
  height: 40px;
  padding-left: 1em;
  padding-right: 1em;
}

.spinner {
  margin: 0px auto 0;
  width: 70px;
  text-align: center;
}

.logo {
  background-position: -10px;
  width: 10rem;
  object-fit: cover;
}

.hidden {
  display: none;
}

.spinner > div.conteiner {
  width: 18px;
  height: 18px;
  background-color: #aaa;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.input-pwb {
  background-color: #ffffff;
}

.select-wlb {
  border-left-width: 0px !important;
  padding-left: 0px;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.9s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.col-0 {
  display: none;
}
</style>
