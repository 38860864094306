import ApiService from './_api.service'

const BucketsService = {


    list: function(args) {
        let queryParams = '?'
        if (args) {
            if (typeof args == 'number') queryParams = args + '/'
            else
                Object.keys(args).forEach(
                    key => {
                        if(args[key]) (queryParams += key + '=' + args[key] + '&')
                    }
                )
        }
        return ApiService.get(`/v1/srs/buckets/${queryParams}`)
    },

    retrieve: function(uuid) {
        if (!uuid) return
        return ApiService.get(`/v1/srs/buckets/${uuid}/`)
    },

 

    download: function(uuid, key) {
        if(!uuid || !key) return

        return ApiService.download(`/v1/srs/buckets/${uuid}/download/?key=${key}`)
    },
    downloadLink: function(uuid, key) {
        if(!uuid || !key) return

        return ApiService.get(`/v1/srs/buckets/${uuid}/download/?key=${key}`)
    },

    upload: function(uuid, file, file_name) {
        if(!uuid || !file) return
        return ApiService.upload(`/v1/srs/buckets/${uuid}/upload/?key=${file_name}`, file)
    }

}

export default BucketsService
export { BucketsService }