import ApiService from './_api.service'


const UsersService = {


    me: function() {
        return ApiService.get(`/v1/iam/users/me/`)
    },

}

export default UsersService
export { UsersService }